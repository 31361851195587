import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiTooltipQuestion, mdiPhoneCheck, mdiCommentQuote, mdiAccountQuestion, mdiYoutube } from '@mdi/js';

import HelpModal from './modals/HelpModal';
import FeedbackModal from './modals/FeedbackModal';
import Breadcrumb from '@components/breadcrumb';
import LinkButton from '@components/link/linkButton';

import { FileTypes, ResourceFiles } from '@src/utils/enums';

import './help.scss';
import { Card, CardBody, CardHeader } from '@trussworks/react-uswds';
import ExternalLink from '@src/app-components/external-link/ExternalLink';
const environment = import.meta.env.VITE_ENVIRONMENT;

const videoWidth = 830;
const videoLinksData = [
  {
    title: 'RRS Navigation',
    description:
      'This four minute video provides an overview of the RRS and explores functional areas of the system and discusses how to access information and submit a request',
    image: 'video-still-rrs-navigation.png',
    width: videoWidth,
    height: Math.round(315 * (videoWidth / 560)), // original dimensions: 560x315
    url: 'https://www.youtube.com/watch?v=24lkoNZvjC0',
  },
];
const imageBasePath = ['test', 'prod'].includes(environment) ? '/rrs' : '';

const Help = connect('doDownloadFile', 'doModalOpen', ({ doDownloadFile, doModalOpen }) => (
  <div className='content-container help-content'>
    <Breadcrumb pathname='Regulatory Request System Help Center' href='/home' hrefText='Home' />
    <h1>How can we help?</h1>

    <Card className='general-support-card'>
      <CardHeader>
        <h3>
          <Icon focusable={false} className='general-support-icon' path={mdiTooltipQuestion} size='24px' />
          General Support & Inquiries Specific to the Regulatory Request System (RRS)
        </h3>
      </CardHeader>
      <CardBody>
        <p>
          Need assistance with navigating the RRS application, encountering technical issues, or require help with
          account setup?
        </p>
        <p>
          Submit your email inquiry by clicking the link below and our support team will help answer your questions and
          resolve issues.
        </p>
        <p>
          If you are unsure of or need additional information regarding the USACE Regulatory Program, please use the
          "Contact Your Local Regulatory Office" link below.
        </p>
        <LinkButton
          className='btn general-support-button'
          onClick={() => doModalOpen(HelpModal)}
          content='Submit a Ticket'
        />
        <span style={{ fontStyle: 'italic', marginLeft: '0.5rem' }}>
          Support agents available 9:00AM - 9:00PM EST Monday - Friday
        </span>
      </CardBody>
    </Card>
    <Card className='office-support-card'>
      <CardHeader>
        <h3>
          <Icon focusable={false} className='office-support-icon' path={mdiPhoneCheck} size='24px' />
          Contact Your Local Regulatory Office
        </h3>
      </CardHeader>
      <CardBody>
        <p>
          Looking for help regarding USACE Regulatory Program guidance, policy, or regulations, or have questions
          related to the content or status of your request?
        </p>
        <p>
          The link below will allow you to contact the USACE district in your area that can provide specialized
          assistance.
        </p>
        <div>
          <LinkButton
            className='btn office-support-button'
            onClick={() => {
              window.open('https://regulatory.ops.usace.army.mil/offices/', '_blank').focus();
            }}
            content='Contact Office'
          />
        </div>
      </CardBody>
    </Card>
    <Card className='feedback-card'>
      <CardHeader>
        <h3>
          <Icon focusable={false} className='feedback-icon' path={mdiCommentQuote} size='24px' />
          Feedback & Suggestions
        </h3>
      </CardHeader>
      <CardBody>
        <p>
          Your feedback drives the improvement of the Regulatory Request System. Whether it's a suggestion for
          additional features, reporting a bug or sharing your overall experience. We're eager to hear from you.
        </p>
        <p>Submit your feedback by clicking the link below.</p>
        <div>
          <LinkButton
            className='btn feedback-button'
            onClick={() => doModalOpen(FeedbackModal)}
            content='Submit Feedback'
          />
        </div>
      </CardBody>
    </Card>
    <Card className='video-card'>
      <CardHeader>
        <h3>
          <Icon focusable={false} className='video-icon' path={mdiYoutube} size='24px' /> Videos
        </h3>
      </CardHeader>
      <CardBody>
        {videoLinksData.map((videoLink) => (
          <div className='video-container' key={videoLink.title}>
            <img
              alt={`${videoLink.title} video still`}
              className='video-image'
              src={`${imageBasePath}/images/video-stills/${videoLink.image}`}
            />
            <div>
              <div className='video-title'>
                {videoLink.title} -
                <ExternalLink className='video-link' href={videoLink.url}>
                  play video
                </ExternalLink>
              </div>
              <div className='video-description'>{videoLink.description}</div>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
    <Card className='logingov-card'>
      <CardHeader>
        <h3>
          <Icon focusable={false} className='logingov-icon' path={mdiAccountQuestion} size='24px' />
          Login.gov Help
        </h3>
      </CardHeader>
      <CardBody>
        <p>
          The Regulatory Request System (RRS) uses Login.gov for authentication. Login.gov has its own support center,
          however here are some helpful hints for Login.gov as it relates to RRS.
        </p>
        <p>Click the link below to access step-by-step instructions on how to set up your account in Login.gov.</p>
        <div>
          <LinkButton
            className='btn logingov-button'
            onClick={() => doDownloadFile(ResourceFiles.LoginGovHelpGuide, FileTypes.Resource)}
            content='Login.gov Help Guide [PDF, 1 page]'
          />
        </div>
      </CardBody>
    </Card>
  </div>
));

export default Help;
