import React from 'react';
import { connect } from 'redux-bundler-react';
import NotLoggedIn from '../NotLoggedIn';

import '../Home/home.scss';
import './updates.scss';

const Updates = connect('selectAuthIsLoggedIn', ({ authIsLoggedIn }) => (
  <>
    {authIsLoggedIn ? (
      <div className='content-container' style={{ marginTop: '1.5rem' }}>
        <h1>Release Notes</h1>
        <h4>Version rrs 2.2.4 - 11/22/2024</h4>
        <h6>New Feature (15)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1220'>RRS-1220</a>] - PN: Update RRS to switch to use ORM web
            service containing list of public notices
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1221'>RRS-1221</a>] - PN: Modify current public notice card on
            home page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1225'>RRS-1225</a>] - PN: Add Public Notice content on Jump To
            page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1292'>RRS-1292</a>] - JPA: Create End Point to serve Questions
            to Front End for a given JPA
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1293'>RRS-1293</a>] - JPA: Implement data model through entity
            framework
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1310'>RRS-1310</a>] - PN: Automated email response to user
            when Comments are submitted
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1345'>RRS-1345</a>] - PN Detail: Handle Broken URLS
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1356'>RRS-1356</a>] - PN: Update Action Icons on Table View
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1357'>RRS-1357</a>] - PN: Add Pin and Increase Zoom Level on
            Map View
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1360'>RRS-1360</a>] - Impacts: Update logic when River/Stream
            Selected
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1379'>RRS-1379</a>] - IP/GP AR: Allow upload of CSV and GDB
            consistently between Map and Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1381'>RRS-1381</a>] - Location and AR Map: Drop a Pin and Zoom
            in on Searched Address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1382'>RRS-1382</a>] - Add a spinner on Dashboard when loading
            projects.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1388'>RRS-1388</a>] - JPA: Create Seeder Logic to populate JPA
            LKP Tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1391'>RRS-1391</a>] - PN: Add User Guidance to Name and
            Comment Fields on Data Entry Form
          </li>
        </ul>
        <h6>Bugs (3)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1283'>RRS-1283</a>] - IP/GP Impact Summary: Hide section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1301'>RRS-1301</a>] - Dashboard: Rerouted Requests showing
            incorrect District
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1393'>RRS-1393</a>] - Sorting not correctly functioning on PN
            page
          </li>
        </ul>
        <h4>Version rrs 2.2.3.1 - 11/14/2024</h4>
        <h6>Bugs (4)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1350'>RRS-1350</a>] - RRS Incorrectly Validating Agent Phone
            One and Phone One Country Code
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1386'>RRS-1386</a>] - AOR Service Endpoint does not fallback
            when primary is unavailable
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1387'>RRS-1387</a>] - AOR Service Fallback Endpoint update for
            status 200 error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1395'>RRS-1395</a>] - PN page displaying error on page load
          </li>
        </ul>
        <h4>Version rrs 2.2.3 - 11/7/2024</h4>
        <h6>New Feature (17)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1237'>RRS-1237</a>] - PN: Create Public Notice Comment Input
            Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1266'>RRS-1266</a>] - PN: Create detail view page for public
            notices
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1288'>RRS-1288</a>] - PN: Update Table and Map View pages to
            link to new Detail Page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1313'>RRS-1313</a>] - ENG 4345 IP: Update PDF for Exp Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1314'>RRS-1314</a>] - ENG 6082 GP: Update PDF for Exp Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1315'>RRS-1315</a>] - ENG 6284 Viol. Complaint: Update PDF for
            Exp Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1316'>RRS-1316</a>] - ENG 6286 Preapp: Update PDF for Exp Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1317'>RRS-1317</a>] - ENG 6295 Agent Auth.: Update PDF for Exp
            Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1318'>RRS-1318</a>] - ENG 6294 Right of Entry: Update PDF for
            Exp Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1319'>RRS-1319</a>] - PN: Create PN Onscreen Help
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1320'>RRS-1320</a>] - ENG 6247 JD Request: Update PDF for Exp
            Date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1321'>RRS-1321</a>] - Form Table: Update the linked ENG forms
            for the newest versions
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1333'>RRS-1333</a>] - PN: Table view of public notices -
            feedback
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1337'>RRS-1337</a>] - IP/GP PDF: Update the PDFs to display
            &quot;See Appendix C&quot; for the Impact Summary data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1338'>RRS-1338</a>] - PN: Address Public Notice Form Spam
            concerns
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1341'>RRS-1341</a>] - PN: Create Export Functionality for
            Public Notice Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1353'>RRS-1353</a>] - UAT 2.2.2: Permitting JPA Process -
            Static Page - Update intro text
          </li>
        </ul>
        <h6>Bug (4)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1307'>RRS-1307</a>] - IP PDF: Nearest Waterbody not populating
            (Draft and Final PDFS)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1335'>RRS-1335</a>] - Location: Update City, County and State
            DB Attribute Limits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1344'>RRS-1344</a>] - Developer Role: Ignore the IsDeveloper
            Flag when updating User Profile
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1352'>RRS-1352</a>] - UAT 2.2.2: Update Spinner Language on
            Location and AR Pages
          </li>
        </ul>
        <h4>Version rrs 2.2.2 - 11/1/2024</h4>
        <h6>New Feature (15)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-405'>RRS-405</a>] - JPA: Create static page describing
            district capabilities
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1211'>RRS-1211</a>] - User Profile: Inconsistent Collection of
            Phone Number (vs rest of Contacts)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1217'>RRS-1217</a>] - PN: Create map view page to display
            location of public notices
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1248'>RRS-1248</a>] - AddressSuggestInput, PhoneInput, Icon,
            and Stepper Accessibility Enhancements
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1257'>RRS-1257</a>] - PDF Generation: Add Country to PDFs for
            Project Location
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1259'>RRS-1259</a>] - Add Banner to application to clearly
            indicate DEV\TEST environments
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1271'>RRS-1271</a>] - PN: Create filtering controls for table
            and map views
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1295'>RRS-1295</a>] - PN: Create temporary public notice url
            table in EF
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1302'>RRS-1302</a>] - Update AOR Services Referenced by RRS
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1304'>RRS-1304</a>] - Export read only PDF for AR, mitigations
            and Impacts.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1305'>RRS-1305</a>] - Extra Geometry validation in RRS
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1311'>RRS-1311</a>] - Im Not Sure: Update static language
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1312'>RRS-1312</a>] - Violation Complaint: Update Notice when
            Anonymous
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1331'>RRS-1331</a>] - JPA: Update static content for Caribbean
            District text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1332'>RRS-1332</a>] - PN: Pagination Feedback for Public
            Notice Table View
          </li>
        </ul>
        <h6>Bugs (8)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-963'>RRS-963</a>] - Display location\address fields on all
            screens consistently
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1268'>RRS-1268</a>] - File Upload: Duplicate Files are not
            revalidated after one has been deleted
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1270'>RRS-1270</a>] - User Profile: Inconsistent Collection of
            Phone Number
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1279'>RRS-1279</a>] - Project Location: Add a spinner on
            project location page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1280'>RRS-1280</a>] - Project location: Correct issue when
            switching between address map view and physical address.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1289'>RRS-1289</a>] - Modify error message labels and
            validation for the Contact Information Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1334'>RRS-1334</a>] - Project Location Validation: Add
            Validation For Missing Geometry
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1336'>RRS-1336</a>] - Location and AR Map: Search never
            returns
          </li>
        </ul>
        <h4>Version rrs 2.2.1 - 10/15/2024</h4>
        <h6>New Feature (12)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1216'>RRS-1216</a>] - PN: Create table view of public notices
            (without filtering)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1239'>RRS-1239</a>] - Dashboard: Update path in Zipped file
            when downloading all files.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1246'>RRS-1246</a>] - React-Select Accessibility Improvements
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1247'>RRS-1247</a>] - TanStackTable, NavBar, Common Form
            Components Accessibility Issues
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1256'>RRS-1256</a>] - PDF Generation: Include Fax Phone
            Country Code on Generated PDFs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1260'>RRS-1260</a>] - Update Email Templates to indicate
            DEV\TEST environments
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1272'>RRS-1272</a>] - Create &quot;Hidden&quot; Public Notice
            Skeleton Page &amp; URL Route
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1273'>RRS-1273</a>] - Pagination for Public notice
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1274'>RRS-1274</a>] - Endpoint to return District code and
            state
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1275'>RRS-1275</a>] - Public notice filtering
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1278'>RRS-1278</a>] - Create Anonymous Endpoint for static
            page describing district capabilities
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1287'>RRS-1287</a>] - (Backend) UAT 2.2.0: Defaulting Country
            code for Phone 2 and Fax Number
          </li>
        </ul>
        <h6>Bug (8)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1245'>RRS-1245</a>] - I&#39;m Not Sure: Notice overlaps
            Navigation buttons
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1249'>RRS-1249</a>] - The Country is not displayed on the
            Location Summary page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1252'>RRS-1252</a>] - Non-logged In users are not able to
            upload .GDB files for project location
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1253'>RRS-1253</a>] - Violation Complaint: Non-logged in Users
            who select to not remain anonymous receive a deactivated &quot;Sign Up/Login&quot; button
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1262'>RRS-1262</a>] - Modify error message labels and
            validation for User Profile form &amp; requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1276'>RRS-1276</a>] - UAT 2.2.0: Defaulting Country code for
            Phone 2 and Fax Number
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1284'>RRS-1284</a>] - Clean up Ilogger Error/Warning/Info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1285'>RRS-1285</a>] - Saving Geometry Data Clean up.
          </li>
        </ul>
        <h4>Version rrs 2.2.0 - 9/27/2024</h4>
        <h6>New Feature (8)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1138'>RRS-1138</a>] - File Upload USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1183'>RRS-1183</a>] - Project Location: Validate Districts for
            line and polygons that extend outside of US
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1215'>RRS-1215</a>] - PN: Develop data model to collect public
            notice comments
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1219'>RRS-1219</a>] - PN: Create folder in AWS S3 bucket to
            save attachments
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1224'>RRS-1224</a>] - PN: Develop Back End code for storing
            public notice data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1226'>RRS-1226</a>] - PN: Create endpoint to pull public
            notice data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1227'>RRS-1227</a>] - Home&gt;Reg. Program Info: Update the
            list of forms and appendices
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1228'>RRS-1228</a>] - Contacts: Update FAX Number to reflect
            Phone formatting, and allow Country Code
          </li>
        </ul>
        <h6>New Feature (15)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1146'>RRS-1146</a>] - Inconsistent field display and
            navigation for submitted requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1176'>RRS-1176</a>] - Misaligned Request Screens for WOTUS
            Delineation Requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1177'>RRS-1177</a>] - &quot;Area Code and Phone Number&quot;
            field misaligned in Contact Information Section of All Request Types
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1179'>RRS-1179</a>] - Impacts and Mitigation Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1199'>RRS-1199</a>] - Modify Validation for Contacts Section
            of Alleged Violators Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1204'>RRS-1204</a>] - Lat Long reverse geo lookup query
            changes Lat Long to nearest address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1205'>RRS-1205</a>] - Location:County is incorrectly storing
            the State value for some requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1207'>RRS-1207</a>] - Duplicate Agent Error Message Displaying
            Behind Modal
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1231'>RRS-1231</a>] - Violation Complaint: Modify Validation
            for Additional Contacts/Witnesses
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1234'>RRS-1234</a>] - IPAC API Issue: Faulty Geometry Parsing
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1236'>RRS-1236</a>] - Add Agent Country Code for Phone Not
            Defaulting to 1
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1240'>RRS-1240</a>] - Validation Messages: Incorrect Input
            Type Referenced
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1243'>RRS-1243</a>] - Impacts and Mitigation Stepper
            Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1244'>RRS-1244</a>] - Proj. Location: Country value needs to
            be stored in the Locations table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1251'>RRS-1251</a>] - Not Sure Modal Crashes on Select of
            Value
          </li>
        </ul>
        <h4>Version rrs 2.1.9.1 - 9/25/2024</h4>
        <h6>New Feature (1)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1250'>RRS-1250</a>] - Profile modal does not logout users from
            keycloak when canceled
          </li>
        </ul>
        <h4>Version rrs 2.1.9 - 9/17/2024</h4>
        <h6>New Feature (10)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1111'>RRS-1111</a>] - Text Input USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1112'>RRS-1112</a>] - Text Area Input USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1113'>RRS-1113</a>] - Date Picker USWDS Implementation (New
            Development)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1116'>RRS-1116</a>] - Checkbox USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1123'>RRS-1123</a>] - Select Input USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1137'>RRS-1137</a>] - Icon USWDS (Trussworks) Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1181'>RRS-1181</a>] - Address Search Component Update (USWDS)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1182'>RRS-1182</a>] - Phone Input Component Update (USWDS)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1184'>RRS-1184</a>] - Validation location on Submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1212'>RRS-1212</a>] - Force action modal
          </li>
        </ul>
        <h6>Bugs (7)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1141'>RRS-1141</a>] - Auto populate address not displaying
            fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1149'>RRS-1149</a>] - IP/GP Authority: When clicking to set
            Authority, Error region not updating
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1198'>RRS-1198</a>] - Remove erroneous character from
            Jurisdiction page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1201'>RRS-1201</a>] - Aquatic Resource Measurement Unit stores
            incorrect value for Area Measurement Type
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1203'>RRS-1203</a>] - Territory Agent &quot;Use Profile&quot;
            does not pull &quot;State&quot; field.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1206'>RRS-1206</a>] - GDB Upload - Improve Error Handling
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1209'>RRS-1209</a>] - Help Modal hover title &quot;[Object
            object]&quot;
          </li>
        </ul>
        <h4>Version rrs 2.1.8 - 9/9/2024</h4>
        <h6>Bugs and Enhancements (26)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1098'>RRS-1098</a>] - AOR Aquatic Resource Validation in Real
            Time
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1100'>RRS-1100</a>] - Backend to deliver consistent and
            meaningful Validation messages
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1102'>RRS-1102</a>] - Mitigation: Update to Handle Ribits
            unavailable condition when calling RIBITS API
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1109'>RRS-1109</a>] - Add Footer to RRS (New Development)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1114'>RRS-1114</a>] - Accordion USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1115'>RRS-1115</a>] - Table (static - not user input) USWDS
            (Trussworks)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1117'>RRS-1117</a>] - Radio Button USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1118'>RRS-1118</a>] - Button USWDS (Trussworks) Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1120'>RRS-1120</a>] - Alert USWDS (Trussworks) Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1122'>RRS-1122</a>] - Side Nav Bar USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1124'>RRS-1124</a>] - Main Nav Bar USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1128'>RRS-1128</a>] - Home Page - Jurisdication: Text Changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1139'>RRS-1139</a>] - AOR validation on submit when endpoints
            are not available
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1140'>RRS-1140</a>] - Error Region: Display field titles and
            change header language
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1157'>RRS-1157</a>] - Project Location: replace GDB Template
            with Project Location specific GDB
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1161'>RRS-1161</a>] - Home Page - Jurisdiction: Revised
            wording for page from SME
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1127'>RRS-1127</a>] - Violation Complaint: Optional Country
            Code in correctly saving as US causing Zip error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1136'>RRS-1136</a>] - Project Location: GDB spanning multiple
            districts does not present selection dropdown
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1143'>RRS-1143</a>] - AR/Impact/Mit Tables: review/validate
            field type validations
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1148'>RRS-1148</a>] - Link opening about:blank page instead of
            destination
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1150'>RRS-1150</a>] - GDB Upload - Geometry Types With Z Index
            No Longer Working
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1156'>RRS-1156</a>] - Maintenance BannerInfo Fetching too
            frequently
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1159'>RRS-1159</a>] - AR table horizontal scroll bar
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1160'>RRS-1160</a>] - GDB Upload - Invalid WKT Issue
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1173'>RRS-1173</a>] - AOR Validation - Endpoint Update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1174'>RRS-1174</a>] - Agent signature populating on Applicant
            line Draft PDFs
          </li>
        </ul>
        <h4>Version rrs 2.1.7.2 (Patch) - 9/4/2024</h4>
        <h6>Bugs & Enhancements (2)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1152'>RRS-1152</a>] - UAT Feedback 2.1.7: AR CSV download not
            functioning correctly
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1158'>RRS-1158</a>] - UAT Feedback 2.1.7.1: Table of Forms
            missing content
          </li>
        </ul>
        <h4>Version RRS v2.1.7 - 8/26/2024</h4>
        <h6>Bugs & Enhancements (13)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-464'>RRS-464</a>] - Update RRS to use new RIBITS API services
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1043'>RRS-1043</a>] - AOR Aquatic Resource Validation on
            Submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1060'>RRS-1060</a>] - Mitigation WS Update: pass both Bank
            Name/Bank ID; Handle RIBITS Unavailable &amp; Not Listed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1090'>RRS-1090</a>] - Standardize &quot;Zip Code&quot; field
            naming in RRS
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1092'>RRS-1092</a>] - Develop data driven approach for
            managing version number on RRS application
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1096'>RRS-1096</a>] - Home: Reg. Program Info: Add list of
            forms and appendices
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1106'>RRS-1106</a>] - Backend Cleanup/Rework Code.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1107'>RRS-1107</a>] - AR/Location/Submit district validation
            pop-up box.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1119'>RRS-1119</a>] - Link USWDS (Trussworks) Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1121'>RRS-1121</a>] - Bread Crumbs USWDS (Trussworks)
            Implementation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1126'>RRS-1126</a>] - Update District API URL (Location) and
            Function Name
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1129'>RRS-1129</a>] - Permitting Info: Update text to USACE
            under State Assumption of Clean Water Act
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1099'>RRS-1099</a>] - Lat Long values not clearing across new
            request modal project location options
          </li>
        </ul>
        <h4>Version RRS v2.1.6 - 8/20/2024</h4>
        <h6>Bugs & Enhancements (13)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-350'>RRS-350</a>] - Contact Info: Add &quot;Applicant&quot;
            Title with large font and red line
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-538'>RRS-538</a>] - Home: Reg. Program Info: Add new Section
            for regulations and links
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-657'>RRS-657</a>] - Home Page - Jurisdiction: Text Change
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-721'>RRS-721</a>] - IP Instructions, Activities involving
            discharge: text and link updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-731'>RRS-731</a>] - Aquatic Resource Inventory: Page
            Navigation and Field Focus
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-789'>RRS-789</a>] - IP Instructions: Update Project Plan
            section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1084'>RRS-1084</a>] - IP: Project Description/Nature of
            Activity Instructions and Help Modal Text update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1088'>RRS-1088</a>] - Modify current External Agency User Role
            functionality
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1101'>RRS-1101</a>] - Permitting Info: Update header and text
            for State Assumption of Clean Water Act
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-264'>RRS-264</a>] - IP/GP General Project Information:
            Selecting Section 404 Authority incorrectly skips sections
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-280'>RRS-280</a>] - IP/GP Gen Project Info: Update links under
            Select Applicable Statutory Authority
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1085'>RRS-1085</a>] - Violation Complaint: Create new Appendix
            A3, correct ENG 6284 Property Owners Name
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1103'>RRS-1103</a>] - Unable to input &quot;Address&quot; into
            contacts / profile
          </li>
        </ul>
        <h4>Version RRS v2.1.5 - 8/12/2024</h4>
        <h6>Bugs & Enhancements (7)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1082'>RRS-1082</a>] - Appendix H: Supporting Info pdf should
            not generate when No supporting information
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1094'>RRS-1094</a>] - USACE Regulator Role &quot;Invalid&quot;
            in Test &amp; Prod
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1028'>RRS-1028</a>] - RRS Generated Files: Implement a Sort
            Order to generated files
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1036'>RRS-1036</a>] - Contacts: Applicant/Agent, User Profile:
            Phone Number changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1088'>RRS-1088</a>] - Modify current External Agency User Role
            functionality
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1089'>RRS-1089</a>] - Disconnect email domain validation from
            User Role type
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1097'>RRS-1097</a>] - Modify Folder and File Names for Draft
            files
          </li>
        </ul>
        <h4>Version RRS v2.1.4 - 8/5/2024</h4>
        <h6 className='mt-3'> Summary of Changes</h6>
        <ul>
          <li>Various Violation Complaint validation and UI improvements</li>
          <li>Various table improvements: CSV upload validations and pagination fixed</li>
          <li>Generate Draft PDFs capability</li>
          <li>Impacts & Mitigations table logic improvements</li>
          <li>Spell check corrections</li>
        </ul>
        <h6>Bugs & Enhancements (23)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-337'>RRS-337</a>] - AR Entry: Enforce latitude \ longitude
            data entry with hemisphere restrictions
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-392'>RRS-392</a>] - Develop data driven approach for managing
            banner text and version number on RRS application
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-959'>RRS-959</a>] - Violation Complaint: Make Property Owner
            Parcel Number optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-960'>RRS-960</a>] - Violation Complaint: Make Phone field
            optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-961'>RRS-961</a>] - Violation Complaint: Contact Information
            Text and position changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1024'>RRS-1024</a>] - IP Instructions: Update Text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1035'>RRS-1035</a>] - IP/GP Species: Change to handling
            Species validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1040'>RRS-1040</a>] - Create endpoint to generate draft PDFs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1041'>RRS-1041</a>] - Ensure generated draft PDFs are read
            only
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1042'>RRS-1042</a>] - Add watermark to draft documents
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1055'>RRS-1055</a>] - Add Generate Draft Button
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1074'>RRS-1074</a>] - Full RRS System: Review/Correction of of
            misspellings with &quot;Spell Checker&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-291'>RRS-291</a>] - Impacts and Mitigation: Rows rearranged
            themselves
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-970'>RRS-970</a>] - Invalid CSV bulk upload kicking users out
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-973'>RRS-973</a>] - Data coming from CSV need to be trimmed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1073'>RRS-1073</a>] - Submitted Email not displaying correct
            District Website
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1075'>RRS-1075</a>] - Aquatic Resource Table Null Row
            Pagination Error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1076'>RRS-1076</a>] - Impacts Type Of Materials Table Cell
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1077'>RRS-1077</a>] - Create New Request modal GDB Template
            download link errors
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1079'>RRS-1079</a>] - Violation Complaint Location: Site Area
            should be optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1080'>RRS-1080</a>] - Generated Documents: ENG6802 has
            agent&#39;s first name as address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1081'>RRS-1081</a>] - Violation Complaint: Instructions text
            update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1087'>RRS-1087</a>] - Merge duplicate address suggest input
            components into a single component
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1087'>RRS-1091</a>] - Agent Address information is populating
            for applicant address
          </li>
        </ul>
        <h4>Version RRS v2.1.3 - 7/26/2024</h4>
        <h6 className='mt-3'> Summary of Changes</h6>
        <ul>
          <li>Improved modal for entering project location information</li>
          <li>Updated GDB Template File</li>
          <li>Mitigation table split into PRM and Bank/ILF tables</li>
          <li>Changes and improvements made to Impacts and Mitigation tables</li>
          <li>Improved GDB validation and error messaging</li>
          <li>Improvements/bugfixes to several PDF templates</li>
          <li>Created endpoint for non-authenticated users to download files from AWS S3 bucket</li>
          <li>Improvement to address intake of for contacts with international addresses</li>
          <li>Shortened file names for generated PDFs (to allow opening when zipped)</li>
          <li>Improvement to user profile error messaging</li>
          <li>Updated USACE district contact information</li>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements (50)</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-531'>RRS-531</a>] - Add instructional text to AR screens
            regarding GDB/CSV use
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-662'>RRS-662</a>] - GP AR - Map missing search Icon
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-663'>RRS-663</a>] - Start a New Request: Allow user to enter
            address of project location
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-683'>RRS-683</a>] - Instructions: Update Delineation section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-723'>RRS-723</a>] - modify email template formats
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-953'>RRS-953</a>] - GP/IP Impact, Mitigation: Calculate
            Proposed Amount when L/W provided
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-984'>RRS-984</a>] - Delineation / JD/Preapp: Make Property
            Owner Email Optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1000'>RRS-1000</a>] - IP/GP/Home-Permitting: Add final Sample
            Drawings to text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1001'>RRS-1001</a>] - Reg Program Information: Add Frequently
            Used Terms document
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1010'>RRS-1010</a>] - IP/GP Mitigation: Adjust column order
            for Mitigation Type , Waters Name
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1017'>RRS-1017</a>] - GP Instructions: Add link for NWP
            Regional Conditions and correct help text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1018'>RRS-1018</a>] - Ensure all ORM Mitigation (Mitigation
            Bank \ In Lieu Fee) business rules are followed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1019'>RRS-1019</a>] - Ensure all ORM Mitigation (PRM )
            business rules are followed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1020'>RRS-1020</a>] - Ensure all ORM Impact business rules are
            followed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1023'>RRS-1023</a>] - Endpoint for downloading static files
            for Anonymous users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1030'>RRS-1030</a>] - GP/IP Impact: Move Area Units column and
            enhance instructional text for L/W/Proposed Amount
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1031'>RRS-1031</a>] - GP/IP Mitigation: Move Area Units column
            and enhance instructional text for L/W/Proposed Amount
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1056'>RRS-1056</a>] - Update RRS to track both Bank/ILF Name
            and Bank/ILF ID
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1057'>RRS-1057</a>] - Front End Changes to track both Bank/ILF
            name and Bank/ILF ID
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1058'>RRS-1058</a>] - Data Update for tracking Bank/ILF Name
            and Bank/ILF ID
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1059'>RRS-1059</a>] - Webservice Update to pass Bank/ILF ID
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1066'>RRS-1066</a>] - Update user profile country code.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1068'>RRS-1068</a>] - Reorder aquatic resource inventory
            columns on screen table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1069'>RRS-1069</a>] - GP/IP Impacts: make Type of Materials
            Being Discharged dynamically required
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1071'>RRS-1071</a>] - Update District Information Seeder file.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-169'>RRS-169</a>] - Generated Documents: Appendix D-1 Bank
            Name incorrectly populated
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-184'>RRS-184</a>] - Mitigations: Split PRM, Bank/ILF into two
            tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-954'>RRS-954</a>] - Enforce relationship between Country and
            State\Territory\Zip
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-976'>RRS-976</a>] - isSiteDesignatedForDISP sends
            &quot;true&quot; instead of boolean true
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-981'>RRS-981</a>] - WOTUS/JD Requests ONLY - Aquatic Resources
            table GDB row count doubles on save
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-982'>RRS-982</a>] - GP/IP Mitigations: Waters Name should not
            be enterable for Banks and ILF
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-989'>RRS-989</a>] - Issue with Appendix C Impact Amount
            Population
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-991'>RRS-991</a>] - Issue with Appendix D-2 (PRM) Population
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-996'>RRS-996</a>] - GDB Row level Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1004'>RRS-1004</a>] - Aquatic Resources table not accepting
            &quot;Measurement Unit&quot; data via upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1006'>RRS-1006</a>] - Mitigations Table - State not being
            cleared when Type is changed.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1007'>RRS-1007</a>] - Generated Document: Extra Long Zip
            filenames fail to open
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1009'>RRS-1009</a>] - Generated Documents: Appendix A1
            displays Incorrect Name on screen
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1021'>RRS-1021</a>] - GP General Project Information: Text
            Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1026'>RRS-1026</a>] - Violation Complaint - Generated PDF
            should not require property owner for alleged violators contacts
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1045'>RRS-1045</a>] - IP/GP Impact Mitigation: Remove
            &quot;Drag and Drop&quot; Text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1050'>RRS-1050</a>] - Generated Documents: Appendix A1 PDF
            displays Incorrect Zip Header
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1051'>RRS-1051</a>] - GP Mitigation: Correct misspelled word
            &quot;Environmental&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1052'>RRS-1052</a>] - GP Mitigation: Requirement incorrectly
            displaying for &quot;Explain how compensatory mitigation...&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1063'>RRS-1063</a>] - Contacts \ Use Profile Data is not
            functioning correctly
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1065'>RRS-1065</a>] - Adjoining Property Owner data entry
            contains unknown error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1070'>RRS-1070</a>] - Impact Summary Row not being generated
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1072'>RRS-1072</a>] - GDB File Upload: Handle WKT Strings
            Exceeding VARCHAR2 Limits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-130'>RRS-130</a>] - Update the GDB template in RRS with new
            version
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-1015'>RRS-1015</a>] - Review./ Assess User Profile Change
            process and provide suggested Messaging
          </li>
        </ul>
        <h4>Version RRS v2.1.2 - 6/24/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-368'>RRS-368</a>] - IP Instructions: Update Optional
            Information section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-462'>RRS-462</a>] - modify district table to include office
            phone numbers and URLs for email templates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-508'>RRS-508</a>] - Update RRS Webservices for Phone Number
            field changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-558'>RRS-558</a>] - IP/GP Other Laws and Regulations: Require
            ESA acknowledgement
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-571'>RRS-571</a>] - IP/GP Instructions: WOTUS link behavior is
            inconsistent
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-603'>RRS-603</a>] - Generated Documents: Create citation
            appendix
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-622'>RRS-622</a>] - Submitted Status Emails
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-648'>RRS-648</a>] - IP General Permit Information: Do not
            allow a Date Approved/Denied in the Future
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-717'>RRS-717</a>] - Update Phone Number fields to accept
            International Phone Number Format
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-950'>RRS-950</a>] - Update JD ENG 6247 Form Expiration Date to
            30-JUN-2024
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-952'>RRS-952</a>] - Update JD Request creation modal text
            &quot;Corps&quot; to &quot;USACE&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-955'>RRS-955</a>] - Update RESTful service:
            get_req_proj_by_district_rms/:district
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-971'>RRS-971</a>] - Pre-App: WOTUS link behavior is
            inconsistent with IP/GP
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-977'>RRS-977</a>] - Feedback on &quot;Auto Populate the Agent
            Authorization Form&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-978'>RRS-978</a>] - Feedback on &quot;Auto Populate the Right
            of Entry Form&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-983'>RRS-983</a>] - GP/IP Impacts: Add Duration - Permanent
            Loss Relationship
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-166'>RRS-166</a>] - Generated Documents: Appendix C
            incorrectly reflecting Permanent Loss
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-169'>RRS-169</a>] - Generated Documents: Appendix D-1 Bank
            Name incorrectly populated
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-208'>RRS-208</a>] - Generated Documents: ENG4345 Proj Inf.
            Sheet missing ESA checkbox data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-219'>RRS-219</a>] - Remove Regulator Dashboard Capabilities
            from RRS
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-235'>RRS-235</a>] - Generated Documents: Signature not
            displaying
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-236'>RRS-236</a>] - Generated Documents: ENG4345 has
            agent&#39;s first name as address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-275'>RRS-275</a>] - Generated Documents: Project Information
            Sheet missing source of material data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-312'>RRS-312</a>] - Generated Documents: Appendices have blank
            second page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-314'>RRS-314</a>] - Generated Documents: Appendix D-1 (or D2)
            generated when no data selected on screen
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-343'>RRS-343</a>] - GP IP Impacts Table not displaying
            Permanent Loss after submitting
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-946'>RRS-946</a>] - AR Table error handling CSV file
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-949'>RRS-949</a>] - Cannot edit Agent info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-965'>RRS-965</a>] - Generated Documents: Ensure Documents do
            not contain &quot;Booz Allen&quot; label
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-968'>RRS-968</a>] - Date Applied And Date Denied Default
            Placeholders Incorrect
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-969'>RRS-969</a>] - GDB Upload Invalid Character Values
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-980'>RRS-980</a>] - Modify/Correct the Filenames for Generated
            Files
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-990'>RRS-990</a>] - Issue with Appendix D Population
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-992'>RRS-992</a>] - 6082 Form Population: Nearest Waterbody
            does not populate block 13
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-993'>RRS-993</a>] - File Upload/Download Special Characters
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-995'>RRS-995</a>] - Division and district do not in user
            profile when USACE regulator.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-997'>RRS-997</a>] - Email failing in DEV
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-998'>RRS-998</a>] - RRS Project in Assigned Status - generated
            file missing
          </li>
        </ul>
        <h4>Version RRS v2.1.1.1 (Patch) - 6/10/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-421'>RRS-421</a>] - Violation Complaint: Update Text (v2)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-495'>RRS-495</a>] - All Components - Supporting Information -
            Text Update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-640'>RRS-640</a>] - Violation Complaint - Instructions -
            Update text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-958'>RRS-958</a>] - GP/IP Impacts: error occurring saving
            Proposed Amount &lt; 1
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-966'>RRS-966</a>] - Unable to save impacts, Ar, mitigation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-967'>RRS-967</a>] - Fails to pull address from USPS address
            repository
          </li>
        </ul>
        <h4>Version RRS v2.1.1 - 6/3/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-362'>RRS-362</a>] - Home &gt; Jurisdiction &gt; Under
            Expand... Scientific Methods - Correct the Automated Wetland Datasheets link
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-416'>RRS-416</a>] - Auto Populate the Agent Authorization Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-447'>RRS-447</a>] - All areas other than GP - Text update to
            USACE vs Corps
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-469'>RRS-469</a>] - modify email recipient list
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-495'>RRS-495</a>] - All Components - Supporting Information -
            Text Update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-534'>RRS-534</a>] - JD - Generated JD Form Issues
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-549'>RRS-549</a>] - Generic Validation Modal for GDB Uploads
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-585'>RRS-585</a>] - RRS Property Owners: Auto Populate the
            Right of Entry Form - 6294
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-622'>RRS-622</a>] - Submitted Status Emails
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-626'>RRS-626</a>] - Auto Populate the Right of Entry Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-635'>RRS-635</a>] - GP General Project Information - dredge
            events text change
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-640'>RRS-640</a>] - Violation Complaint - Instructions -
            Update text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-717'>RRS-717</a>] - Update Phone Number fields to accept
            International Phone Number Format
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-837'>RRS-837</a>] - Delineation - Instructions - Text Change
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-840'>RRS-840</a>] - RRS - Contact Information: Auto Populate
            the Agent Authorization Form - 6295
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-160'>RRS-160</a>] - Replace Violation Complaint Generated Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-171'>RRS-171</a>] - Issue 24: IP Aquatic Resources is
            defaulting to 0 measurement amount
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-178'>RRS-178</a>] - Stepper should always show Green when
            Status is not Draft
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-195'>RRS-195</a>] - Start a New Request incorrectly available
            to Regulators
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-199'>RRS-199</a>] - Issue 4: General Permit Information should
            not allow both Date Approved and Denied
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-210'>RRS-210</a>] - Instructions: Read More results in 404
            error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-216'>RRS-216</a>] - Pulling PM info rework
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-217'>RRS-217</a>] - Issue 3: Country field missing from User
            Profile Address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-218'>RRS-218</a>] - General Permit Information should not
            allow a Date Approved/Denied that is prior to the Date Applied
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-233'>RRS-233</a>] - Issue 22: IP National Wild and Scenic
            Rivers contains unclickable link
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-241'>RRS-241</a>] - Issue 27: IP and GP Mitigation Sections
            contains Credit Units not available to all districts
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-246'>RRS-246</a>] - Issue 14: IP/GP Manually entered Date
            fields - validations not functioning and allow 6 digit year
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-249'>RRS-249</a>] - Issue 2: Use Profile Data doesn’t copy
            Country field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-256'>RRS-256</a>] - Change &quot;Nationwide&quot; to
            &quot;Individual&quot; on the Impacts Section for Individual Permits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-257'>RRS-257</a>] - GP: &quot;Will the proposed activity
            result in a loss&quot; Defaulting incorrectly
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-266'>RRS-266</a>] - Issue 25: IP Impact and Mitigation
            contains incorrect text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-293'>RRS-293</a>] - 256 Character Limit not enforced on many
            table fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-306'>RRS-306</a>] - Issue 5: Date fields allow invalid Year
            (1111)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-307'>RRS-307</a>] - IP Screen incorrectly showing GP only
            mitigation field &quot;Explain how...&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-313'>RRS-313</a>] - IP Instructions: PreApplication link
            results in data loss
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/RRS-948'>RRS-948</a>] - IP/GP User Entered End Date Field Data is
            Auto Clearing Corresponding Start Date Field Data
          </li>
        </ul>
        <h4>Version RRS v2.1.0.1 - 5/17/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26902'>ORM-26902</a>] - Issue 1: 4000 Character Limit not
            enforced for Direction to Project
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26920'>ORM-26920</a>] - Issue 18: Incorrect text showing in
            Endangered Species section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26921'>ORM-26921</a>] - Issue 19: Unable to Save IP because of
            issues with Endangered Species section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26923'>ORM-26923</a>] - Issue 21: IP National Wild and Scenic
            Rivers contains text errors
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26967'>ORM-26967</a>] - Agent Authorization Form Required
            warning message is misspelled
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26968'>ORM-26968</a>] - General Information Section: Hide
            Piling and Coastal Sections until further notice
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26969'>ORM-26969</a>] - Permits.Permit_Form_Type is
            incorrectly NULL for GP
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26971'>ORM-26971</a>] - Aquatic Resource Type list in GDB
            allows value of NONE and is not validated
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26972'>ORM-26972</a>] - Permits.Requested_Permits_Type value
            missing Permit word
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26820'>ORM-26820</a>] - RRS JD / Delin - Generated Project
            Information Sheet population issues
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26956'>ORM-26956</a>] - Update Home Page Banner with post
            release 2.1.0 content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26973'>ORM-26973</a>] - Add IPAC Species List Retry Button
          </li>
        </ul>
        <h4>Version RRS v2.1.0 - 5/14/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26902'>ORM-26902</a>] - Issue 1: 4000 Character Limit not
            enforced for Direction to Project
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26913'>ORM-26913</a>] - Issue 11: Update Shoreline
            Stabilization guidance text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26918'>ORM-26918</a>] - Issue 16: Adjoining Property Owner
            Section showing regardless of permit type
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26919'>ORM-26919</a>] - Issue 17: Salutation field not saving
            in Adjacent Property Owner section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26921'>ORM-26921</a>] - Issue 19: Unable to Save IP because of
            issues with Endangered Species section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26922'>ORM-26922</a>] - Issue 20: Incorrect field label and
            help for IP Historic Properties
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26923'>ORM-26923</a>] - Issue 21: IP National Wild and Scenic
            Rivers contains text errors
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26925'>ORM-26925</a>] - Issue 23: IP Civil Works Projects
            Section contains incorrect text for IP
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26928'>ORM-26928</a>] - Issue 26: IP Mitigation section
            contains a GP question
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26930'>ORM-26930</a>] - Issue 28: Mitigation Section
            incorrectly shows Permitee Responsible fields for Mitigation Bank and In Lieu Fee Programs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26931'>ORM-26931</a>] - Issue 29: IP Validation error on
            Submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26940'>ORM-26940</a>] - Supporting Permit section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26941'>ORM-26941</a>] - Appendix B PDF for Aquatic Resource
            Inventory displays incorrect local waterway data
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26943'>ORM-26943</a>] - &quot;City&quot; field is being
            populated Incorrectly with Township info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26821'>ORM-26821</a>] - Delineation and JD - Property Owners
            Right of Entry Form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26956'>ORM-26956</a>] - Update Home Page Banner with post
            release 2.1.0 content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23381'>ORM-23381</a>] - RRS Path 2a: IP or Unknown Permit
            Application - ENG 4345
          </li>
        </ul>
        <h4>Version RRS v2.0.6 - 5/10/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Added Individual Permit (ENG 4345) Request Type</li>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26647'>ORM-26647</a>] - &quot;City&quot; field is being
            populated Incorrectly with District info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26861'>ORM-26861</a>] - Cannot add agents
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26863'>ORM-26863</a>] - At least one Impact is required
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26875'>ORM-26875</a>] - Non-USA Lat/Long error message missing
            capitalization and punctuation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26881'>ORM-26881</a>] - Cannot load project
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26891'>ORM-26891</a>] - ENG4345 - IP: General Project
            Information - Dredging question is not saving selected value
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23563'>ORM-23563</a>] - Validation data in the backend
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23564'>ORM-23564</a>] - Develop IP General project information
            section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24401'>ORM-24401</a>] - Validation data on the UI
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24416'>ORM-24416</a>] - Develop Instructions Sections
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24469'>ORM-24469</a>] - Contacts section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24470'>ORM-24470</a>] - Integrate Contacts Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24471'>ORM-24471</a>] - Integrate/Develop Adjoining Property
            Owners Section into path 2a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24473'>ORM-24473</a>] - Integrate/Develop Historic Property
            and Endangered Species Section into path 2a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24474'>ORM-24474</a>] - Integrate Aquatic Resource Inventory
            Section into Path 2a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24475'>ORM-24475</a>] - Integrate Impacts
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24477'>ORM-24477</a>] - Integrate Supporting Information
            Upload Section into path 2a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24478'>ORM-24478</a>] - Integrate Certify, Sign, Submit
            Section into path 2a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24483'>ORM-24483</a>] - Change Existing Tables to support IP
            form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24498'>ORM-24498</a>] - Path 2a General Project Information
            Section (path specific)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24512'>ORM-24512</a>] - Output Path 2a Data to PDF (Project
            Info and ENG4345)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24539'>ORM-24539</a>] - &quot;New Request&quot; route path 2a
            selection to path 2a form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25964'>ORM-25964</a>] - Create ENG4345 Template for PDF
            Generation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26093'>ORM-26093</a>] - develop view of requests by location
            for use with ESRI tools
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26252'>ORM-26252</a>] - Integrate mitigation section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26253'>ORM-26253</a>] - Build out controller
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26254'>ORM-26254</a>] - Build out repository
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26312'>ORM-26312</a>] - enforce minimum decimal latitude and
            longitude values for project location and aquatic resources
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26598'>ORM-26598</a>] - 6082 Review: modify guidance text
            until combo paths enabled
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26809'>ORM-26809</a>] - GP AR Upload Map focus
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26812'>ORM-26812</a>] - RRS Dashboard - add capability to
            email the PM
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26813'>ORM-26813</a>] - RRS - Contact Information - Agent
            Authorization (6082 and JD/delin path) - Update Text in link
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26814'>ORM-26814</a>] - Contact Information - Phone validation
            (all paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26818'>ORM-26818</a>] - GP - Gen. Project Information - Verify
            Pile Driving Logic
          </li>
        </ul>
        <h4>Version RRS v2.0.5 - 5/2/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Improved validations for General Permits. Ability to submit General Permit requests is fixed</li>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26648'>ORM-26648</a>] - Selected IPAC Animals are not
            transferred to final .pdf
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26723'>ORM-26723</a>] - USACE Regulatory Role: Should not be
            able to Apply for a Permit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26726'>ORM-26726</a>] - Contact Information Section: Error
            message displays when saving a form that contains special characters
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26309'>ORM-26309</a>] - incorporate LOP wording to permit home
            page related to Adjacent property owners
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26697'>ORM-26697</a>] - GP Start a New Request Notice: Update
            GP text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26698'>ORM-26698</a>] - GP Instructions: Update Text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26699'>ORM-26699</a>] - GP Instructions: Update Text (part II)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26700'>ORM-26700</a>] - GP Instructions: Update/Add Hyperlink
            and Eternal Resource Pop Up Box
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26701'>ORM-26701</a>] - GP Contact Information: Use Profile
            Data Button
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26703'>ORM-26703</a>] - GP Contact Information updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26704'>ORM-26704</a>] - GP General Project Information - Link
            to NWP information Page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26705'>ORM-26705</a>] - GP Other Laws and Regulations - ESA
            Text Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26707'>ORM-26707</a>] - External Resource Window Popups Text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26708'>ORM-26708</a>] - GP Other Laws and Regulations -
            Historic Properties Text Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26709'>ORM-26709</a>] - GP Other Laws and Regulations -USACE
            Civil Work Projects (Section 408) Text Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26710'>ORM-26710</a>] - GP Aquatic Resource Inventory Screen
            focus
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26712'>ORM-26712</a>] - GP Aquatic Resource Inventory - entry
            of AR Rows
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26716'>ORM-26716</a>] - GP Impacts - Text updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26717'>ORM-26717</a>] - GP Impacts - Impact Summary fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26718'>ORM-26718</a>] - GP Mitigation - Text updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26719'>ORM-26719</a>] - GP Mitigation - Help Modals Display
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26720'>ORM-26720</a>] - GP Mitigation - Help Modals Text
            Update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26721'>ORM-26721</a>] - GP Mitigation - Update Display of
            Explain…
          </li>
        </ul>
        <h4>Version RRS v2.0.4 - 4/26/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Improved validations for all request types. Ability to submit General Permit requests is fixed</li>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26574'>ORM-26574</a>] - Users cannot see requests in Dashboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26692'>ORM-26692</a>] - Save Progress Features is not saving
            data to the correct fields when any field is NULL
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26759'>ORM-26759</a>] - Some GP sections are not sending
            appropriate IDs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26760'>ORM-26760</a>] - General Project Information section -
            clean up fields when NULL
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26711'>ORM-26711</a>] - GP Aquatic Resource Inventory AR
            Scroll Bar
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26714'>ORM-26714</a>] - GP Aquatic Resource Inventory Text
            Changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26715'>ORM-26715</a>] - GP Aquatic Resource Inventory -
            Cowardin List
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26722'>ORM-26722</a>] - GP Supporting Information - Dropdown
            Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26724'>ORM-26724</a>] - Property Owners: Error when adding to
            JD and Pre-application Forms
          </li>
        </ul>
        <h4>Version RRS v2.0.3 - 4/15/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Forms Application - Users will now receive e-mails when change in application status occurs</li>
          <li>Forms Application - fixed bug with auto scrolling to fields containing errors</li>
          <li>
            Forms Application - enabled ability for users to save partially completed sections (removed section validity
            requirement)
          </li>
          <li>Forms Application - added maxValue attributes to all text input fields</li>
          <li>
            General Permit - General Project Information - Fixed various bugs and validation issues with this section
          </li>
          <li>Aquatic Resource Section - Added Local Waterway field</li>
          <li>UI - Updated application navbar / form header to be more 508 compliant (using USWDS components)</li>
          <li>Various bugfixes, static content modifications, stability improvements and validation fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26474'>ORM-26474</a>] - Clearing pileTypes, pileDetails, and
            pileInstallMethods when certain dropdowns are &quot;No&quot;
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26496'>ORM-26496</a>] - Invalid required fields not scrolling
            into view/focusing
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26504'>ORM-26504</a>] - cannot create multiple aquatic
            resources from map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26513'>ORM-26513</a>] - Incorrect landingModalSeen session
            value being set
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26514'>ORM-26514</a>] - Bulk Upload of Property Owners does
            not function correctly
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26532'>ORM-26532</a>] - TEST - NWP Contact Information - Agent
            contacts not stored for request
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26538'>ORM-26538</a>] - inconsistent red\green indicators for
            Other Law and Regulations section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26539'>ORM-26539</a>] - inconsistent display of IPAC list in
            Other Laws and Regulations section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26559'>ORM-26559</a>] - Nationwide Permit “Earliest Start
            Date” field is not allowing for future dates.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26560'>ORM-26560</a>] - PCN should not be required
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26591'>ORM-26591</a>] - Dashboard - A11y - Unable to tab into
            buttons on Tanstack table using keyboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26592'>ORM-26592</a>] - Allow City name to contain period
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26596'>ORM-26596</a>] - 6082 Review: modify guidance text on
            Project Location section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26597'>ORM-26597</a>] - 6082 Review: Local Waterway field
            should not have been removed for Aquatic Resources
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26599'>ORM-26599</a>] - All Input Fields: Limit MaxValue to
            match database column size
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26600'>ORM-26600</a>] - Contact Information: Validate email
            address
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26608'>ORM-26608</a>] - Incremental Save Functionality (for
            incomplete sections)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26613'>ORM-26613</a>] - Typo in generated Delineation report
            pdf
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24623'>ORM-24623</a>] - Create Polling Background Service
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24624'>ORM-24624</a>] - Send Templated Emails
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26194'>ORM-26194</a>] - Assigned Status Emails
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26264'>ORM-26264</a>] - Appendix G Pile Driving Information
            Sheet
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26265'>ORM-26265</a>] - Appendix F Shoreline Stabilization
            Information Sheet
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26266'>ORM-26266</a>] - Appendix E Dredging Information Sheet
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26267'>ORM-26267</a>] - Appendix D Mitigation Inventory
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26268'>ORM-26268</a>] - Appendix C Impact Inventory
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26269'>ORM-26269</a>] - Appendix B Aquatic Resources
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26270'>ORM-26270</a>] - Appendix A Adjoining Property Owners
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26473'>ORM-26473</a>] - IPAC API availability monitoring and
            Add IPAC API call and logic to a redux bundle
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26489'>ORM-26489</a>] - Loading Screen/Indicator to make sure
            all files are loaded on form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26535'>ORM-26535</a>] - Update piling language and questions
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26536'>ORM-26536</a>] - modify content controls on general
            project information section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26537'>ORM-26537</a>] - General project information and
            supporting information sections not saving
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26540'>ORM-26540</a>] - Aquatic Resources Section: address
            multiple concerns
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26543'>ORM-26543</a>] - Impacts \ Mitigation Section: Remove
            term GDB from bulk upload title
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26544'>ORM-26544</a>] - General: Exit Form Button is not
            operational
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26545'>ORM-26545</a>] - Contacts: allow agents to import their
            profile
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26555'>ORM-26555</a>] - DB retry after disconnected.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26558'>ORM-26558</a>] - Modify Nationwide Permit piling
            information required for submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26582'>ORM-26582</a>] - Address Validation and Formatting
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26593'>ORM-26593</a>] - General Project Info - New Dredging
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26614'>ORM-26614</a>] - Add Localwaterway to CSV template
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26615'>ORM-26615</a>] - Update Measurement labels, static text
            in General Project Info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26630'>ORM-26630</a>] - Other laws and Regulations requirement
            change
          </li>
        </ul>
        <h4>Version RRS v2.0.2 - 3/28/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Fixed issue with unable to submit a PreApplication</li>
          <li>
            Corrected Permits data mappings and validations and made General Project Information section improvements
          </li>
          <li>Fixed Impacts & Mitigations table logic for conditional rendering and calculations</li>
          <li>Added guidance language addressing special characters for Supporting Documents upload</li>
          <li>Various static content fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26359'>ORM-26359</a>] - Error status modal shows if there are
            no projects in the dashboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26361'>ORM-26361</a>] - FE not sending all PreApp fields to BE
            in TEST
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26418'>ORM-26418</a>] - Permits - Correcting field validations
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26419'>ORM-26419</a>] - Other Laws &amp; Regulations -
            additionalAffectedSpecies concatenating string with a comma in front
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26471'>ORM-26471</a>] - Conditional rendering on mitigation
            table based on mitigation type
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26472'>ORM-26472</a>] - All fields should be readonly for a
            submitted permit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26488'>ORM-26488</a>] - Mitigation Type Permittee Responsible
            On-Site and Off-Site validation error on BankOrILF field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26506'>ORM-26506</a>] - Disable Property Owner bulk upload
            button
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26508'>ORM-26508</a>] - Phone Number One and Number Two
            validation are inconsistent
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26510'>ORM-26510</a>] - Impacts &amp; Mitigation proposedAcres
            calculation showing NaN
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26512'>ORM-26512</a>] - Inconsistent wording on Session
            Expiration Warning Screen
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24513'>ORM-24513</a>] - Output Path 2b Data to PDF (Project
            Info and ENG6082)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25965'>ORM-25965</a>] - Create ENG6082 Template for PDF
            Generation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26310'>ORM-26310</a>] - update guidance on file upload pages
            to address special characters
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26414'>ORM-26414</a>] - impact amounts should be length\width
            OR amount - not both
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26416'>ORM-26416</a>] - 404 Authority &amp; Piling section
            validation is out of synch
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26424'>ORM-26424</a>] - ENG 6082 incorrectly allows additional
            supporting information after being submitted
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26425'>ORM-26425</a>] - Add Section 408 Help Modal
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26426'>ORM-26426</a>] - Add additional guidance to other laws
            and regulations section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26427'>ORM-26427</a>] - Disposal Map not functioning as
            intended
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26428'>ORM-26428</a>] - Calendars in dredging and piling
            sections should allow future dates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26429'>ORM-26429</a>] - Read More here link results in error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26430'>ORM-26430</a>] - General Project Information section
            field ordering
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26432'>ORM-26432</a>] - General Project Information section is
            only partially saving in piling section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26433'>ORM-26433</a>] - Text changes on RRS Permitting Page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26434'>ORM-26434</a>] - mitigation amounts should be
            length\width OR amount - not both
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26467'>ORM-26467</a>] - Dredge Section clearing behavior below
            dredge type field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26515'>ORM-26515</a>] - Revisit map guidance
          </li>
        </ul>
        <h4>Version RRS v2.0.1 - 3/19/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Fixed issue with district not being populated when using map location search feature</li>
          <li>Fixed issue with conditional logic for all sections in [Permits - General Project Information]</li>
          <li>Fixed issue with validation logic for Mitigation table in [Permits - Impacts and Mitigation]</li>
          <li>Fixed backend data validation bugs</li>
          <li>Changed "list other approvals" in [Permits - General Permit Information]</li>
          <li>Fixed issue with NWPs in [Permits - General Permit Information] dropdown not populating in order</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26406'>ORM-26406</a>] - General Project Information -
            defaultValues select Input values do not handle DB Boolean properly.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26407'>ORM-26407</a>] - General Project Information -
            Conditional rendering of sections broken
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26408'>ORM-26408</a>] - Impacts and Mitigations - Mitigation
            table validation is incorrect
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26409'>ORM-26409</a>] - Other Laws and Regulations -
            defaultValues select Input values do not handle DB Boolean properly.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26418'>ORM-26418</a>] - Permits - Correcting field validations
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26421'>ORM-26421</a>] - Permits - hasSupportingDocuments need
            to be added to permits data model
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24425'>ORM-24425</a>] - Validate data in the backend
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24514'>ORM-24514</a>] - GP_Permits_LKP table and Static data
            Insertion SQL
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26250'>ORM-26250</a>] - Validate data on the UI
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26411'>ORM-26411</a>] - location by address doesn't result in
            calculation of district
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26413'>ORM-26413</a>] - &quot;List other approvals&quot; field
            should be optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26415'>ORM-26415</a>] - dredging section hide &amp; show and
            validation is out of synch
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26417'>ORM-26417</a>] - &quot;if the terms of the nationwide
            permit&quot; should be optional
          </li>
        </ul>
        <h4>Version RRS v2.0 - 3/19/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Added General Permit Path</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23382'>ORM-23382</a>] RRS Path 2b: GP Permit Application -
            (ENG 6082)
          </li>
        </ul>
        <h4>Version RRS v1.1.0 - 3/7/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Added maintenance banner for future downtime announcements</li>
          <li>Added project location map tips</li>
          <li>Static content changes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26170'>ORM-26170</a>] - JD reasons are not being saved
            correctly in the DB
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26171'>ORM-26171</a>] - Previous file numbers are not being
            sent from FE to BE
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26173'>ORM-26173</a>] - Previous file numbers do not get
            deleted correctly.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26245'>ORM-26245</a>] - FE Error Handling
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25863'>ORM-25863</a>] - &quot;component&quot; ID sequence auto
            increment
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26056'>ORM-26056</a>] - Develop Announcement
            Banner/Maintenance Notice
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26208'>ORM-26208</a>] - Add Map Tips to Project Location Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26275'>ORM-26275</a>] - modify guidance on Support page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26277'>ORM-26277</a>] - modify JD request type guidance for
            Agents
          </li>
        </ul>
        <h4>Version RRS v1.0.4 (Patch) - 3/7/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various Bug Fixes</li>
        </ul>
        <h6>Bugs</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26221'>ORM-26221</a>] - Unable to Validate Form Signature as
            Agent
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26249'>ORM-26249</a>] - Error retrieving file names with
            special characters
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26256'>ORM-26256</a>] - PROD - Dashboard will not load
            requests once PM assigned
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26339'>ORM-26339</a>] - Agent dropdown
          </li>
        </ul>
        <h4>Version RRS v1.0.3 (Patch) - 3/1/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various Bug Fixes</li>
        </ul>
        <h6>Bugs</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26221'>ORM-26221</a>] - Unable to Validate Form Signature as
            Agent
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26245'>ORM-26245</a>] - FE Error Handling
          </li>
        </ul>
        <h4>Version RRS v1.0.2 (Patch) - 2/16/2024</h4>
        NOTE: development version bumped from 0.6.7.2 ={'>'} 1.0.2 to match production environment
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Various Patches and Bug Fixes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26097'>ORM-26097</a>] - Supporting Information upload error -
            Credential Error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26167'>ORM-26167</a>] - ORM File Number Error Modal
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26146'>ORM-26146</a>] - modify attribution of leaflet use in
            RRS maps
          </li>
        </ul>
        <h4>Version RRS v0.6.7.1 - 2/8/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Fixed Page Redirect & Refresh "client error" and general page navigation issues</li>
          <li>Handled GDB Template Case Update</li>
          <li>Delineation Report - Fixed phone number type and phone number being saved to generated PDF</li>
          <li>Static content changes</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26045'>ORM-26045</a>] - Handle GDB Template Case Update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26000'>ORM-26000</a>] - page refresh results in client error -
            differs from dev and test behavior
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26066'>ORM-26066</a>] - modify link and information to start a
            Preapp
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-26067'>ORM-26067</a>] - modify RRS Support Icon text
          </li>
        </ul>
        <h4>Version RRS v0.6.7 - 1/24/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Static content changes</li>
          <li>
            Dashboard - Updated dashboard buttons to create more visual distinctions, created tags for the request
            statuses and rearranged columns, displayed ORM DA Number, and project cancellation logic
          </li>
          <li>Help - Added Help Page</li>
          <li>Application - Updated Notice Banner</li>
          <li>Project Location Modal - Added Measurement Unit Type option (Acre or Square Feet)</li>
          <li>Addressed outstanding 508 compliance items from ANDI report</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25951'>ORM-25951</a>] - Update URL For USACE EEZ Geolocation
            Service
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24403'>ORM-24403</a>] - allow public user to Cancel request
            before submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25754'>ORM-25754</a>] - Consider options to avoid covering
            Reason : other fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25755'>ORM-25755</a>] - Edit desaturated elements on box /
            font color
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25756'>ORM-25756</a>] - Visual distinctions between ‘draft’
            and ‘submitted’ reports
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25760'>ORM-25760</a>] - Help icon for 408 definition
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25761'>ORM-25761</a>] - Add more measurement options
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25814'>ORM-25814</a>] - Static text change
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25815'>ORM-25815</a>] - Static text change for permitting
            module
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25882'>ORM-25882</a>] - Home
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25883'>ORM-25883</a>] - Request Page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25884'>ORM-25884</a>] - Regulatory Program 101
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25885'>ORM-25885</a>] - Getting Started
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25886'>ORM-25886</a>] - Jurisdiction
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25887'>ORM-25887</a>] - Permitting
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25888'>ORM-25888</a>] - Regulatory Announcements
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25889'>ORM-25889</a>] - Mitigation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25890'>ORM-25890</a>] - Report Violations
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25899'>ORM-25899</a>] - Relabel my dashboard button text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25906'>ORM-25906</a>] - Develop Help Landing Page for RRS
            application
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25908'>ORM-25908</a>] - Display ORM DA Number on dashboard
            once assigned
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25937'>ORM-25937</a>] - HQ requested Home Page modifications
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25940'>ORM-25940</a>] - Add Measurement Option Dropdown
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25941'>ORM-25941</a>] - Make Aquatic Resource Type and JD Type
            Fields Optional
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25942'>ORM-25942</a>] - Fix state property override on CSV/GDB
            upload
          </li>
        </ul>
        <h4>Version RRS v0.6.6.1 - 1/17/2024</h4>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25702'>ORM-25702</a>] - Develop AR Bulk Upload Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25898'>ORM-25898</a>] - Request Status in Request Forms
          </li>
        </ul>
        <h4>Version RRS v0.6.6 - 1/12/2024</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Complete rework of the Aquatic Resources Section (now uses inline grid table)</li>
          <li>Added bulk upload capability for Aquatic Resources (.gdb, .csv, .xlsx, .txt)</li>
          <li>Fixed issue with district not registering for Gulf of Mexico</li>
          <li>Fixed random user session logouts while using the application</li>
          <li>Added ability to upload multiple files at once and set max file size count and limits</li>
          <li>
            Fixed Property Owner and Supporting Documents section color validation when accessing an existing request.
            The sections will not be green unless user acknowledges sections
          </li>
          <li>Updated static content in several areas</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25561'>ORM-25561</a>] - New Request Modal - District not
            registering for Gulf of Mexico{' '}
          </li>

          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25536'>ORM-25536</a>] - Use Workflow status LKP for status
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25591'>ORM-25591</a>] - Logging in through login.gov
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25690'>ORM-25690</a>] - Multiple supporting docs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25702'>ORM-25702</a>] - Develop AR Bulk Upload Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25721'>ORM-25721</a>] - Execute stored procedures from .Net
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25752'>ORM-25752</a>] - External link identifiers
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25753'>ORM-25753</a>] - Arrow marks on accordion drop-down
            headers
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25758'>ORM-25758</a>] - 100MB file limit clarification
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25764'>ORM-25764</a>] - Static text change
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25767'>ORM-25767</a>] - My Dashboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25768'>ORM-25768</a>] - Clarifications for Right of Entry and
            Supporting Information
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25770'>ORM-25770</a>] - Supporting information section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25798'>ORM-25798</a>] - getAllProjects API cleanup
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25799'>ORM-25799</a>] - OCC Date in .net
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25800'>ORM-25800</a>] - Use workflow LKP and use short names
            for timeline.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25801'>ORM-25801</a>] - Request Stored Procedures
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25816'>ORM-25816</a>] - JD Request delineation section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25819'>ORM-25819</a>] - File upload limits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25828'>ORM-25828</a>] - Set Multi-File Upload Limits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25833'>ORM-25833</a>] - Additional Property Owners and
            Supporting Documents
          </li>
        </ul>
        <h4>Version RRS v0.6.5 - 12/20/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>
            Updated request type selection for creating a new request with checkboxes and certain selections are routed
            to generate certain PDFs
          </li>
          <li>
            File upload - User can bulk download files into zip files from both Supporting Documents sections and the
            dashboard
          </li>
          <li>
            Aquatic Resources - Fixed GDB upload issues if ARs contain empty arrays and auto-populate draw measurements
          </li>
          <li>
            Improved signature date logic. Should be able to save any date (current date or any date prior) and display
            that stored date.
          </li>
          <li>Updated static content in several areas</li>
          <li>
            Improved form and address validations and stepper workflow for all requests and forms, including:
            <ul>
              <li>Added validation highlighting to all modals/forms</li>
              <li>Added district validation checks for project location modals</li>
              <li>Reworked the way form buttons work (always clickable and provide feedback for invalid data)</li>
              <li>Fixed validation of tables within forms</li>
              <li>Added additional error messaging to various locations</li>
              <li>Forms application now allows users to jump from section to section</li>
              <li>
                Added notification to Certify, Sign, Submit section if form is not completely filled out and valid
              </li>
              <li>Various styling changes/fixes throughout the forms application</li>
              <li>
                Request Status box: Draft, Signed, Submitted. Draft = in progress, Signed = form completed and signature
                added but not submitted, Submitted = form completed and signed and submitted
              </li>
            </ul>
          </li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25716'>ORM-25716</a>] - Signature date showing incorrect date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25548'>ORM-25548</a>] - Bulk download and zip all supporting
            documents
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25549'>ORM-25549</a>] - Auto-populate draw measurements for
            ARs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25563'>ORM-25563</a>] - Form/Modal Validation Autofocus/Error
            Scrolling
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25629'>ORM-25629</a>] - Static Content - Permitting Getting
            Started Page - (48)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25689'>ORM-25689</a>] - Completed JD Forms
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25691'>ORM-25691</a>] - PRE-APP General Project Info
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25692'>ORM-25692</a>] - GDB upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25700'>ORM-25700</a>] - Signature date
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25701'>ORM-25701</a>] - Contact validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25708'>ORM-25708</a>] - Address Validation validationCard
            sometimes shows when modal is open
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25715'>ORM-25715</a>] - PDF Generation - Combo path
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25720'>ORM-25720</a>] - Address Validation Button cannot call
            endpoint when not logged in
          </li>
        </ul>
        <h4>Version RRS v0.6.4 - 12/11/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>
            Jurisdictional Determination - Added "Request to Relationship" field and populate values in Block E44 in
            generated PDF
          </li>
          <li>Pre-application - Added "I'm not sure" option to ESA field picklist</li>
          <li>
            Dashboard - Fixed issue with request count and added new action buttons in the table. User can delete a
            draft request and download files from a submitted request, including downloading the completed generated PDF
          </li>
          <li>
            Project Location and Map Interface - Fixed issue with centering map position, point location not showing for
            a submitted request, and rearranged map buttons
          </li>
          <li>File Upload - Fixed issue with uploading supporting documents</li>
          <li>
            Fixed issue with Aquatic Resources data not displaying on the map, made Cowardin code optional, and fixed AR
            validations
          </li>
          <li>
            Agent Contact Information section displays by default if logged in as an Agent. "Use Profile Data" button is
            available for adding Agent contact information.
          </li>
          <li>
            Improved signature logic for signing a request. If applicant, signature validates with applicant's name. If
            agent, signature validates with user profile name.
          </li>
          <li>Improved address validation to provide address text suggestions and to support P.O. boxes.</li>
          <li>Updated static content in several areas</li>
        </ul>
        <h6>Bugs & Enhancements</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25543'>ORM-25543</a>] - JD Previous File Numbers field - NOW
            needs to be NWO
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25544'>ORM-25544</a>] - Project Location - point location not
            shown for a submitted request
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25545'>ORM-25545</a>] - JD - Multiple users have gotten a
            &quot;check logs&quot; error on submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25546'>ORM-25546</a>] - Project Location - User location shows
            as different location on map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25547'>ORM-25547</a>] - Users not able to sign their request
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25558'>ORM-25558</a>] - Dashboard Page shows requests as 0
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25562'>ORM-25562</a>] - AR Data not updating on map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25577'>ORM-25577</a>] - Submitting Violation Complaint fails
            if a site area is entered then deleted
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25578'>ORM-25578</a>] - Non-anonymous readonly violation
            complaint shows signature as &quot;Anonymous&quot; instead of user&#39;s name
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25579'>ORM-25579</a>] - S3 file upload TagValue error
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25580'>ORM-25580</a>] - USACE Regulator Request Page - disable
            &quot;Start a New Request&quot; and &quot;I&#39;m Not Sure&quot; buttons
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25603'>ORM-25603</a>] - Request fails to submit when file
            upload fails
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25508'>ORM-25508</a>] - JD Request Static Content Updates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25534'>ORM-25534</a>] - Modify timeline data model to support
            request workflow
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25541'>ORM-25541</a>] - User ability to download completed
            generated PDFs
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25542'>ORM-25542</a>] - Add &quot;I&#39;m not sure&quot;
            option to Pre-App ESA field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25550'>ORM-25550</a>] - Add &quot;Use Profile Data&quot;
            button for Agent Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25556'>ORM-25556</a>] - 0.6.3 Static Content Changes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25557'>ORM-25557</a>] - Make Cowardin code optional.
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25559'>ORM-25559</a>] - Move Agent Authorization Form Link
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25560'>ORM-25560</a>] - Allow P.O. boxes in Address Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25564'>ORM-25564</a>] - JD Form Checkboxes - Block E44
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25570'>ORM-25570</a>] - Validation improvement
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25571'>ORM-25571</a>] - Logging improvement
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25581'>ORM-25581</a>] - Highlight AR rows red if AR Types are
            not yet selected from gdb upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25587'>ORM-25587</a>] - WOTUS link
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25588'>ORM-25588</a>] - Document Citation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25589'>ORM-25589</a>] - Permitting Getting Started Page-
            Language update
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25590'>ORM-25590</a>] - Delete Draft requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25598'>ORM-25598</a>] - LKP tables for AR
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25599'>ORM-25599</a>] - AR measurement type linear
            auto-populate feet
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25600'>ORM-25600</a>] - Data layer box on the map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25608'>ORM-25608</a>] - Address lookup to support P.O Boxed
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25620'>ORM-25620</a>] - JD Form Checkboxes - Block E44
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25628'>ORM-25628</a>] - PDF Generation (Section 4 of JD)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25652'>ORM-25652</a>] - Suggested Address Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25657'>ORM-25657</a>] - Delete Draft requests on Dashboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25660'>ORM-25660</a>] - Timeline SignatureDate duplicates
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25669'>ORM-25669</a>] - Backend_Bulk download and zip all
            supporting documents
          </li>
        </ul>
        <h4>Version RRS v0.6.3 - 11/20/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Pre-application - Add "I'm Not Sure" option to Led Federal Agency dropdown field</li>
          <li>Violation Complaint - Updated generated document template</li>
          <li>
            Project Location - Fixed issue with loading a GDB file. Added support for line/lineString and .zip files
          </li>
          <li>Map Interface - Add four additional layers to base map</li>
          <li>File Upload - Agent Authorization and Right of Entry file upload accepts both PDF and DOCX files</li>
          <li>File Upload - Fixed issue uploading files more than 30MB. Implemented a file size limit of 100MB</li>
          <li>
            File Upload - Drag-n-drop target box styling changes as user hovers dragged file in and out of the box
          </li>
          <li>Getting Started Guide - Fixed issue with dropdown not displaying picklist options</li>
          <li>
            Project Location - Fixed issue with the search option to find location and digitizing a boundary on the map
          </li>
          <li>CSS/styling to reduce header and footer size to provide more space for page content</li>
          <li>Updated static content in several areas</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25345'>ORM-25345</a>] - Keycloak/Login.gov - Investigate
            logout issue
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25467'>ORM-25467</a>] - Add &quot;I&#39;m Not Sure&quot;
            option to Pre-App Dropdown
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25468'>ORM-25468</a>] - Remove Client Comments from VC
            Document
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25469'>ORM-25469</a>] - Add Additional Layers to Base Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25470'>ORM-25470</a>] - Add support for line/linestring
            geometries and .zip files GDB Upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25472'>ORM-25472</a>] - Modify language on Jurisdiction page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25473'>ORM-25473</a>] - Remove link on CWBI text
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25475'>ORM-25475</a>] - Unable to load large supporting info
            file (&gt;= 30 Mb)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25476'>ORM-25476</a>] - Update I&#39;m Not Sure Modal
            statements
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25488'>ORM-25488</a>] - Inform user about file size 100 MB
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25489'>ORM-25489</a>] - File size validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25509'>ORM-25509</a>] - Violation Complaint redirect on
            creation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25510'>ORM-25510</a>] - File upload error handling
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25511'>ORM-25511</a>] - v0.6.2 Static content feedback fixes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25523'>ORM-25523</a>] - Validation for null/empty lists.
          </li>
        </ul>
        <h4>Version RRS v0.6.2 - 11/9/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Home Page - "Get Started" button is now functional.</li>
          <li>Home Page - Made non-functioning buttons more apparent.</li>
          <li>
            Project Creation Modal - Geometry tools no longer hide, default functionality is to use last drawn geometry.
          </li>
          <li>Project Creation Modal - Fixed issue with Project Area requiring a leading 0.</li>
          <li>
            Project Creation Modal - Fixed issue with latitude and longitude text inputs not updating geometry on map.
          </li>
          <li>Forms Application - Fixed issue with duplicate Applicants being created on project save.</li>
          <li>Forms Application - Fixed issue with contacts not updating properly.</li>
          <li>Forms Application - Fixed timestamp on "Save Progress" button.</li>
          <li>
            Forms Application - While in ReadOnly mode, pressing back button no longer alerts user of potential loss of
            data; "Save Progress" button no longer displayed.
          </li>
          <li>
            Forms Application - Previous File Number regex has been adjusted to accommodate a proper range of years
            (1900-3000).
          </li>
          <li>Dashboard - UI changes have been made to accommodate smaller screen resolutions.</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25449'>ORM-25449</a>] - Duplicate contacts for all requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25450'>ORM-25450</a>] - Show correct time for last modified
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25451'>ORM-25451</a>] - Return UTC time in the JSON
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25452'>ORM-25452</a>] - Email does not get saved
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25453'>ORM-25453</a>] - Exit Form and Save progress buttons
            after submit
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25454'>ORM-25454</a>] - Include City in the generated PDF
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25455'>ORM-25455</a>] - Project area should accept .XXXX
            without the first 0
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25456'>ORM-25456</a>] - Remove YEAR filter on file number
          </li>
        </ul>
        <h4>Version RRS v0.6.1 - 11/3/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>KMS Marketing Feedback UI Changes</li>
          <li>
            Feedback Items from RRS v0.5 (see Excel sheet attached to{' '}
            <a href='https://jira.il2.dso.mil/browse/ORM-25373'>ORM-25373</a> for more information)
          </li>
          <li>Added ability to bulk upload property owners - (template download does not work)</li>
          <li>Project Location moved from form to modal accessible using a button in the form header</li>
          <li>Tested production environment connections/settings</li>
          <li>Various bugfixes/improvements</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25291'>ORM-25291</a>] - Re-skin template and pages
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25292'>ORM-25292</a>] - Reorganize content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25298'>ORM-25298</a>] - Replace Icons and Images
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25349'>ORM-25349</a>] - Update UI and components to be 508
            compliant (ONGOING)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25361'>ORM-25361</a>] - Property Owner Bulk Upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25368'>ORM-25368</a>] - Create Modal for Submission
            Confirmation (Non-Email)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25373'>ORM-25373</a>] - Client Feedback for RRS v0.5
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25377'>ORM-25377</a>] - Project Location - duplicative
            information
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25378'>ORM-25378</a>] - Project Location - API Providing
            incorrect town/city for lat/lng
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25379'>ORM-25379</a>] - Client Feedback v0.5 - Static fixes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25390'>ORM-25390</a>] - Production Database
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25423'>ORM-25423</a>] - Move ORM fields out of Project
            Location
          </li>
        </ul>
        <h4>Version RRS v0.6.0 - 10/20/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Updated Aquatic Resource Types to reflect PRE2015_POSTSACKETT and AMENDED2023RULE Regimes</li>
          <li>
            State and Jurisdictional Determination Type will now filter the list of available Aquatic Resource Types
            (Regulation Type is now a read-only field)
          </li>
          <li>Pre-application - Moved Property Owners to separate section</li>
          <li>
            Updated static content in several areas (see Excel sheet attached to{' '}
            <a href='https://jira.il2.dso.mil/browse/ORM-25299'>ORM-25299</a> for more information)
          </li>
          <li>Various bugfixes/improvements</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25342'>ORM-25342</a>] - Update Aquatic Resource Regimes and
            Types
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25344'>ORM-25344</a>] - PreApp - Move Property Owners to
            separate section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25346'>ORM-25346</a>] - AR_Type and State Regulation tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25347'>ORM-25347</a>] - AR Types and State regulation scripts
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25348'>ORM-25348</a>] - Client Feedback - Static text and
            styling fixes
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25354'>ORM-25354</a>] - Code Cleanup and TODOs
          </li>
        </ul>
        <h4>Version RRS v0.5 - 10/12/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Updated links throughout the application with the latest Agent Authorization and Right of Entry files</li>
          <li>Fixed issue with map not centering/zooming on project location</li>
          <li>Fixed issue with project area defaulting to 0</li>
          <li>Fixed issue with webmap scrolling when moused over, user must now click map first to interact with it</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23553'>ORM-23553</a>] - Display Aquatic Resource Geometries on
            Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23568'>ORM-23568</a>] - Develop RRS Delineation Report Request
            Front End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23569'>ORM-23569</a>] - Develop RRS Aquatic Resources Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23570'>ORM-23570</a>] - Develop RRS Aquatic Resources Front
            End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24380'>ORM-24380</a>] - Display Project Location Boundary
            Geometry on Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24426'>ORM-24426</a>] - Integrate Web Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24427'>ORM-24427</a>] - Integrate AG-Grid Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24428'>ORM-24428</a>] - Create Modal for AR Entry
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24430'>ORM-24430</a>] - Display GDB results on table and map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24431'>ORM-24431</a>] - AR Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24442'>ORM-24442</a>] - Path 1a General Project Information
            Section (path specific)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24454'>ORM-24454</a>] - Integrate General Project Information
            Section into path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24455'>ORM-24455</a>] - Integrate Contacts Section into path
            1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24456'>ORM-24456</a>] - Integrate Property Owner Section into
            path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24457'>ORM-24457</a>] - Integrate Aquatic Resource Inventory
            Section into path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24458'>ORM-24458</a>] - Integrate Supporting Information
            Section into path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24459'>ORM-24459</a>] - Integrate Certify, Sign, Submit
            Section into path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24466'>ORM-24466</a>] - Integrate Project Location Section
            into path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24467'>ORM-24467</a>] - Path 1a front-end back-end data
            exchange
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24479'>ORM-24479</a>] - Integrate Instructions Section into
            path 1a
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24481'>ORM-24481</a>] - Path 1a Section Validation Logic
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24494'>ORM-24494</a>] - Allow User to Add Point, Line or
            Polygon to Web Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24495'>ORM-24495</a>] - Web Map Geolocation Control
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24510'>ORM-24510</a>] - Aquatic Resource Geometry Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24534'>ORM-24534</a>] - Replace graphic(s) in path 1a
            instructions section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25012'>ORM-25012</a>] - Save line and polygon data as geometry
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25142'>ORM-25142</a>] - Create Geometries Table
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25216'>ORM-25216</a>] - Create Script for Geometry Column and
            Index
          </li>
        </ul>
        <h6> Epic</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23377'>ORM-23377</a>] - RRS Aquatic Resources (multiple Paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23379'>ORM-23379</a>] - RRS Path 1a:
            Delineation/Jurisdictional Determination – Submit a Delineation Report and/or Request a Jurisdictional
            Determination (Agents)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23552'>ORM-23552</a>] - RRS Mapping Interface
          </li>
        </ul>
        <h4>Version RRS v0.4 - 09/25/2023</h4>
        <h6 className='mt-3'> Feedback Items Addressed</h6>
        <ul>
          <li>Dashboard button double clicks</li>
          <li>Getting Started page dropdown answer list</li>
          <li>Typo in Pre-App instructions sections: add "a"</li>
          <li>Updates section will include current release items and items from previous releases</li>
          <li>I'm Not Sure modal reds static text changes</li>
          <li>Jurisdiction page static text changes for JD definition</li>
          <li>Static test updates from Jurisdiction and Delineation requirements document</li>
        </ul>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23565'>ORM-23565</a>] - Develop RRS JD Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23566'>ORM-23566</a>] - Develop RRS JD Front End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23567'>ORM-23567</a>] - Develop RRS Delineation Report Back
            End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24389'>ORM-24389</a>] - Develop External Feedback Form User
            Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24390'>ORM-24390</a>] - Develop External Feedback Endpoint
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24391'>ORM-24391</a>] - Develop External Feedback Data Model
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24392'>ORM-24392</a>] - External Feedback User Interface Input
            Field Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24393'>ORM-24393</a>] - Integrate External Feedback API in
            User Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24394'>ORM-24394</a>] - External Feedback Multi-file S3 Upload
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24460'>ORM-24460</a>] - Integrate General Project Information
            Section into path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24461'>ORM-24461</a>] - Integrate Contacts Section into path
            1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24462'>ORM-24462</a>] - Integrate Property Owners Section into
            path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24463'>ORM-24463</a>] - Integrate Project Location Section
            into path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24464'>ORM-24464</a>] - Integrate Supporting Information
            Section into path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24465'>ORM-24465</a>] - Integrate Certify, Sign, Submit
            Section into path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24468'>ORM-24468</a>] - Path 1b front-end back-end data
            exchange
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24480'>ORM-24480</a>] - Integrate Instructions Section into
            path 1b
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24482'>ORM-24482</a>] - Path 1b Section Validation Logic
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24497'>ORM-24497</a>] - Path 1b General Project Information
            Section (path specific)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24535'>ORM-24535</a>] - Replace graphic(s) in path 1b
            instructions section
          </li>
        </ul>
        <h6> Epic</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23380'>ORM-23380</a>] - RRS Path 1b:
            Delineation/Jurisdictional Determination – Request for Corps to Conduct a Delineation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24257'>ORM-24257</a>] - RRS Develop Forms to Collect and
            Review External Feedback to Support Beta Testing
          </li>
        </ul>
        <h4>Version RRS v0.3 - 09/14/2023</h4>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23520'>ORM-23520</a>] - Automatically Store PDF in Repository
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23530'>ORM-23530</a>] - Supporting Documentation Display List
            of Uploaded Files
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23559'>ORM-23559</a>] - Develop RRS Pre-application Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23560'>ORM-23560</a>] - Develop RRS Pre-application Front End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24386'>ORM-24386</a>] - Integrate Supporting Documentation S3
            File Operations API in User Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24387'>ORM-24387</a>] - Display indicator for when file is
            still loading or file successfully uploads
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24529'>ORM-24529</a>] - Path 3 General Project Information
            Section (path specific)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24541'>ORM-24541</a>] - &quot;New Request&quot; route path 3
            selection to path 3 form front end
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24563'>ORM-24563</a>] - Create Supporting Documentation form
            to upload file and add metadata
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24569'>ORM-24569</a>] - Integrate Contacts Section into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24570'>ORM-24570</a>] - Integrate Project Location Section
            into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24571'>ORM-24571</a>] - Integrate Supporting Information
            Section into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24572'>ORM-24572</a>] - Integrate Certify, Sign, Submit
            Section into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24573'>ORM-24573</a>] - Integrate General Project Information
            Section into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24574'>ORM-24574</a>] - Develop/Integrate Instructions Section
            into path 3
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24575'>ORM-24575</a>] - Path 3 front-end back-end data
            exchange
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24576'>ORM-24576</a>] - Path 3 Section Validation Logic
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24577'>ORM-24577</a>] - Output Path 3 Data to PDF
            (Pre-application form)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24585'>ORM-24585</a>] - Integrate Supporting Information
            section into path 5
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24946'>ORM-24946</a>] - Delete a file - Supporting
            Documentation (Path 5)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24947'>ORM-24947</a>] - Download a file - Supporting
            Documentation (Path 5)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24948'>ORM-24948</a>] - Replace a file - Supporting
            Documentation (Path 5)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24949'>ORM-24949</a>] - Upload a file - Supporting
            Documentation (Path 5)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24950'>ORM-24950</a>] - List all files for a User&#39;s
            Projects
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24952'>ORM-24952</a>] - Retrieve Amazon Tags for a file
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24970'>ORM-24970</a>] - Output Path 5 Data to PDF (Regulatory
            Violation Complaint)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25010'>ORM-25010</a>] - &quot;New Request&quot; route path 3
            selection to path 3 form backend
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25013'>ORM-25013</a>] - Pre-application DataBase
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25022'>ORM-25022</a>] - Develop RRS Pre-Application data model
            and integrate with backend API
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25060'>ORM-25060</a>] - Rename PDF properties
          </li>
        </ul>
        <h6> Epic</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23378'>ORM-23378</a>] - RRS Submission of Files for Supporting
            Documentation Section (all Paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23383'>ORM-23383</a>] - RRS Path 3: Pre-application &amp;
            Scoping Report
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23509'>ORM-23509</a>] - RRS Generate PDFs to Support
            Administrative Record
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24418'>ORM-24418</a>] - RRS Request Page
          </li>
        </ul>
        <h4>Version RRS v0.2 - 08/25/2023</h4>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23518'>ORM-23518</a>] - Develop RRS Project Information Back
            End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23519'>ORM-23519</a>] - Develop RRS Project Information Front
            End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23555'>ORM-23555</a>] - Develop Regulatory Violation Complaint
            API
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23556'>ORM-23556</a>] - Path 5: Develop Instructions section
            UI
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23571'>ORM-23571</a>] - Develop RRS Contacts Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23572'>ORM-23572</a>] - Develop RRS Contacts Front End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23573'>ORM-23573</a>] - Develop RRS Project Location Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23574'>ORM-23574</a>] - Develop RRS Project Location Front End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23593'>ORM-23593</a>] - Display Dashboard User Interface for
            Applicant/Agent users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24239'>ORM-24239</a>] - RRS Request Timeline Backend
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24369'>ORM-24369</a>] - Project Location Front End Input Field
            Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24372'>ORM-24372</a>] - Project Location Web Map
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24374'>ORM-24374</a>] - Project Location Geocoding API
            Integration
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24375'>ORM-24375</a>] - Project Location USACE District API
            Integration
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24376'>ORM-24376</a>] - Project Location PLSS API Integration
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24377'>ORM-24377</a>] - Contacts Address Geolocation API
            Integration
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24378'>ORM-24378</a>] - FE-Contacts Field Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24381'>ORM-24381</a>] - Display Dashboard User Interface for
            External Agency users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24382'>ORM-24382</a>] - Develop Regulatory Violation Complaint
            Data Model
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24383'>ORM-24383</a>] - Path 5: Field and section validation
            logic
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24384'>ORM-24384</a>] - Path 5 user interface/backend api data
            exchange
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24385'>ORM-24385</a>] - Path 5: Anonymous and Authenticated
            User Business Logic
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24452'>ORM-24452</a>] - Backend - Add District to the FE_Model
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24453'>ORM-24453</a>] - Backend - GET project records for all
            projects
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24496'>ORM-24496</a>] - Web Map Base Map (OSM)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24516'>ORM-24516</a>] - Develop &quot;I&#39;m Not Sure&quot;
            Wizard Modal User Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24517'>ORM-24517</a>] - &quot;I&#39;m Not Sure&quot; route
            path 1a questions to path 1a form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24518'>ORM-24518</a>] - &quot;I&#39;m Not Sure&quot; route
            path 1b questions to path 1b form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24519'>ORM-24519</a>] - &quot;I&#39;m Not Sure&quot; route
            path 2a questions to path 2a form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24520'>ORM-24520</a>] - &quot;I&#39;m Not Sure&quot; route
            path 2b questions to path 2b form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24521'>ORM-24521</a>] - &quot;I&#39;m Not Sure&quot; route
            path 3 questions to path 3 form
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24530'>ORM-24530</a>] - Path 5 General Site Information (path
            specific)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24531'>ORM-24531</a>] - Add Tax Parcel Number/GPIN field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24533'>ORM-24533</a>] - Add Name of Nearest Waterbody field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24582'>ORM-24582</a>] - Path 5: Develop General Site
            Information section UI
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24583'>ORM-24583</a>] - Integrate Contacts Information section
            into path 5
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24584'>ORM-24584</a>] - Integrate Project Location section
            into path 5
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24586'>ORM-24586</a>] - Path 5: Develop Certify, Sign, Submit
            section UI
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24621'>ORM-24621</a>] - Section 508 Compliance
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24653'>ORM-24653</a>] - Refactor Project Location Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24654'>ORM-24654</a>] - Refactor Contact Information Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24767'>ORM-24767</a>] - Develop Interface for Project
            Creation/Selection/Modification
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24788'>ORM-24788</a>] - Add Project Modal to Dropdown Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24829'>ORM-24829</a>] - Refactor Map Component
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24831'>ORM-24831</a>] - Refactor Certify, Sign, Submit Section
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24858'>ORM-24858</a>] - Backend - Contacts FE_Model and
            Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24859'>ORM-24859</a>] - Backend - Project FE_Model and Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24860'>ORM-24860</a>] - Backend - Location FE_Model and
            Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24861'>ORM-24861</a>] - Backend - Request FE_Model and Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24864'>ORM-24864</a>] - Backend - Parcel FE_Model and Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24865'>ORM-24865</a>] - Backend - Timeline FE_Model and
            Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24866'>ORM-24866</a>] - Backend - PreviousFileNumber FE_Model
            and Mapping
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24938'>ORM-24938</a>] - Backend - Create FE_Dashboard model
          </li>
        </ul>
        <h6> Epic</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23375'>ORM-23375</a>] - RRS Project Location (all Paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23376'>ORM-23376</a>] - RRS Contacts (all Paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23385'>ORM-23385</a>] - RRS Path 5: Regulatory Violation
            Complaint (Unauthorized Activity / Non-Compliance)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23505'>ORM-23505</a>] - RRS Project Information (all Paths)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23512'>ORM-23512</a>] - RSS Dashboard
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24258'>ORM-24258</a>] - RRS Establish Help Desk
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24651'>ORM-24651</a>] - Refactor Form UI Sections/Components
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24863'>ORM-24863</a>] - RRS Data Structure Models and Mapping
            between Front End and Back End
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-25016'>ORM-25016</a>] - RRS I'm Not Sure Wizard
          </li>
        </ul>
        <h4>Version RRS v0.1 - 08/01/2023</h4>
        <h6> Enhancement</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23554'>ORM-23554</a>] - Develop Homepage User Interface Layout
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23591'>ORM-23591</a>] - Incorporate latest USACE static
            content requirements
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24259'>ORM-24259</a>] - Incorporate Client and Beta Testing
            Feedback
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24276'>ORM-24276</a>] - Restrict User Interface capabilities
            based on user role
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24277'>ORM-24277</a>] - Develop Authorization Schemes by User
            Roles
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24278'>ORM-24278</a>] - Develop RRS User Profile API
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24279'>ORM-24279</a>] - Develop RRS User Profile Data Model
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24280'>ORM-24280</a>] - Develop RRS User Profile User
            Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24366'>ORM-24366</a>] - Display certain input fields for
            Applicant/Agent users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24367'>ORM-24367</a>] - Display certain input fields for
            External Agency users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24368'>ORM-24368</a>] - Display certain input fields for USACE
            Regulator users
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24370'>ORM-24370</a>] - Integrate User Profile API in User
            Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24371'>ORM-24371</a>] - User Profile User Interface Input
            Field Validation
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24434'>ORM-24434</a>] - Display Regulatory Program 101 Static
            Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24435'>ORM-24435</a>] - Display Getting Started Guide Static
            Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24436'>ORM-24436</a>] - Display Jurisdiction Static Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24437'>ORM-24437</a>] - Display Permitting Static Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24438'>ORM-24438</a>] - Display Public Notices Static Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24439'>ORM-24439</a>] - Display Regulatory Announcements
            Static Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24440'>ORM-24440</a>] - Display Mitigation Static Content
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24444'>ORM-24444</a>] - Create Database Seeder
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24446'>ORM-24446</a>] - Static Data - Division and District
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24448'>ORM-24448</a>] - Static Data - State Regulation Waters
            Type
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24542'>ORM-24542</a>] - Develop RRS Request Page User
            Interface
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24628'>ORM-24628</a>] - Display certain input fields for
            External Agency users Backend
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24655'>ORM-24655</a>] - Refactor Form UI Input Components
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24684'>ORM-24684</a>] - Update Project table with common
            fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24685'>ORM-24685</a>] - Update location table with common
            fields
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24686'>ORM-24686</a>] - Merge all contact tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24687'>ORM-24687</a>] - Update PKs for 1 - Many tables for
            versioning process
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24688'>ORM-24688</a>] - Create one common table for permits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24689'>ORM-24689</a>] - Update Project request reason field
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24700'>ORM-24700</a>] - RRS Support Administrative Record
            (Transaction Log)
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24728'>ORM-24728</a>] - Update Regulatory Violation Complaint
            tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24756'>ORM-24756</a>] - Add CreatedBy and ModifiedBy
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24757'>ORM-24757</a>] - Change Projects table to Requests
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24758'>ORM-24758</a>] - 1 to Many relationship between
            Requests and permits
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24787'>ORM-24787</a>] - Refactor UI using newly created UI
            components
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24827'>ORM-24827</a>] - Refactor User Profile Section
          </li>
        </ul>
        <h6> Epic</h6>
        <ul>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23510'>ORM-23510</a>] - RRS User Permissions
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23511'>ORM-23511</a>] - RRS User Profile
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-23513'>ORM-23513</a>] - RRS Homepage
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24305'>ORM-24305</a>] - RRS Data Model Review
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24418'>ORM-24418</a>] - RRS Request Page
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24443'>ORM-24443</a>] - RRS Static Reference Tables
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24651'>ORM-24651</a>] - Refactor Form UI Sections/Components
          </li>
          <li>
            [<a href='https://jira.il2.dso.mil/browse/ORM-24694'>ORM-24694</a>] - RRS Incorporate Data Model
            Recommendations
          </li>
        </ul>
      </div>
    ) : (
      <NotLoggedIn />
    )}
  </>
));

export default Updates;
