import { connect } from 'redux-bundler-react';
import { Alert, Card, CardBody, CardHeader } from '@trussworks/react-uswds';

import Breadcrumb from '@components/breadcrumb/index.js';
import GeneratePDF from './components/GeneratePDF.jsx';
import NotFound from '../NotFound/index.jsx';
import BannerSettings from './components/BannerSettings.jsx';
import ManageVersion from './components/ManageVersion.jsx';

import './developerDashboard.scss';

const DeveloperDashboard = connect('selectUserIsDeveloper', ({ userIsDeveloper }) => (
  <>
    {userIsDeveloper ? (
      <div className='content-container'>
        <Breadcrumb pathname='RRS Developer Dashboard' href='/home' hrefText='Home' />
        <h1>RRS Developer Dashboard</h1>
        <Card className='general-support-card'>
          <CardHeader>
            <h4>Manual PDF Generation</h4>
          </CardHeader>
          <CardBody>
            Regenerate submitted requests PDFs by providing the Project ID, Request ID, and Version in the field below.
            <GeneratePDF />
          </CardBody>
        </Card>
        <Card className='office-support-card'>
          <CardHeader>
            <h4>Maintenance Banner Control Center</h4>
          </CardHeader>
          <CardBody>
            <p>Post and/or enable/disable maintenance banner</p>
            <Alert noIcon slim heading='Example' headingLevel='h5' className='callout'>
              The Regulatory Request System (RRS) will be down for scheduled maintenance on 7/3/2024 starting at 6:00
              P.M. Eastern Standard Time. Estimated downtime will be approximately 15-30 minutes.
            </Alert>
            <p className='card-subtitle mb-2 ml-2'></p>
            <BannerSettings />
          </CardBody>
        </Card>
        <Card className='feedback-card'>
          <CardHeader>
            <h3>Manual Version Number Update</h3>
          </CardHeader>
          <CardBody>
            <p className='card-text'>Manually update RRS release version number</p>
            <ManageVersion />
          </CardBody>
        </Card>
        <Card className='logingov-card'>
          <CardHeader>
            <h3>External API Statuses</h3>
          </CardHeader>
          <CardBody>** Under Construction **</CardBody>
        </Card>
      </div>
    ) : (
      <NotFound />
    )}
  </>
));

export default DeveloperDashboard;
