import { useEffect } from 'react';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';

import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContactTypes } from '@src/utils/enums';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

const schema = yup.object().shape({
  ...contactInputValidation.nameRequired,
  ...contactInputValidation.addressRequired,
  ...contactInputValidation.phoneRequired,
  ...contactInputValidation.faxOptional,
  ...contactInputValidation.emailOptional,
}, [contactInputValidation.faxOptionalDependencies]);

const AddPropertyOwnerModal = connect(
  'doModalClose',
  ({
    doModalClose,
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly
  }) => {

    const formatParcelNumbersOutput = (data) => data?.map((obj) => ({ label: obj.parcelNumber, value: obj.parcelNumber }));
    const defaultValues = {
      contactType: ContactTypes.PropertyOwner,
      parcels: formatParcelNumbersOutput(data?.parcels) ? formatParcelNumbersOutput(data.parcels) : [],
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      emailAddress: data?.emailAddress ?? null,
      faxCountryCode: data?.faxCountryCode ?? '1',
      faxPhone: data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOne ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneTwo: data?.phoneTwo ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? '1',
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? 'US',
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setFocus, watch, getValues, trigger } = methods;

    const watchParcels = watch('parcels');

    const onSave = () => {
      if (isValid) {
        const formatParcelNumbers = () => watchParcels.map((obj) => ({ parcelNumber: obj.value }));
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.PropertyOwner,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
          faxPhone: contactFields?.faxPhone?.replace(/\D/g, '') ?? undefined,
        };
        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex((el) => el.contactID === id) : id;
          contactFieldsWithIDs.parcels = watchParcels ? formatParcelNumbers() : [];
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        }
        else {
          contactFieldsWithIDs.parcels = watchParcels ? formatParcelNumbers() : [];
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title={`${edit ? 'Edit' : 'Add'} Property Owner`}>
          {errors && <ErrorSummary errors={errors} modalID='addPropertyOwnerModal' type='modal' />}
          <section className='modal-body' id='addPropertyOwnerModal'>
            <div className='container-fluid'>
              <ContactFields type={ContactTypes.PropertyOwner} label={ContactTypes.PropertyOwner} isReadOnly={isReadOnly} requireEmail={false} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            saveText={edit ? 'Apply Changes' : 'Add'}
            customClosingLogic
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider >
    );
  }
);

export default AddPropertyOwnerModal;
