import { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import { mdiCloseOctagon, mdiPlusBox } from '@mdi/js';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import statusModal from '@forms/components/modals/statusModal';
import FileInput from '@components/file-input/FileInput';

import { ErrorMessages, GeometryTypes } from '@src/utils/enums';
import { tError, tSuccess } from '@src/utils/toast-helpers';

import './headerCell.scss';

const cleanValue = (value) => value?.trim().replace(/°/g, '');

const generateMultiPointGeoJson = (data) => ({
  type: GeometryTypes.MultiPoint,
  coordinates: data?.map((item) => [parseFloat(item.Longitude), parseFloat(item.Latitude)]),
});

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(e.target.error);
    reader.readAsArrayBuffer(file);
  });

// @TODO: HeaderCell and AquaticResourceHeaderCell can be combined into one component
const AquaticResourcesHeaderCell = connect(
  'doGetGeometryData',
  'doResetComplexStateField',
  'doSecondaryModalOpen',
  'doValidateGeometryInValidDistrict',
  'selectArCsvData',
  ({
    doGetGeometryData,
    doResetComplexStateField,
    doSecondaryModalOpen,
    doValidateGeometryInValidDistrict,
    arCsvData,
    table,
    addFeaturesFromRows,
  }) => {
    const meta = table.options.meta;
    const selectedRows = table.getSelectedRowModel().rows;
    const [selectAll, setSelectAll] = useState(false);
    const columns = useMemo(() => table.getAllColumns(), [table]);
    const columnHeaders = columns?.map((column) => column.columnDef?.header);
    const csvData = arCsvData ? [arCsvData] : [];

    const toggleSelectAll = () => {
      setSelectAll(!selectAll);
      table.toggleAllRowsSelected(!selectAll);
    };

    const removeRows = () => {
      meta.removeSelectedRows(table.getSelectedRowModel().rows.map((row) => row.index));
      table.resetRowSelection();
    };

    const validateData = async (rowData) => {
      const providedHeaders = [...new Set(rowData.flatMap((data) => Object.keys(data)))];
      const headersMatch = providedHeaders?.every((header) => columnHeaders.includes(header.trim()));
      const numbersValid = rowData?.map((feature) => {
        const latitude = cleanValue(feature?.Latitude);
        const longitude = cleanValue(feature?.Longitude);
        const measurementAmount = cleanValue(feature?.['Measurement Amount']);
        const isValidCoordinate =
          !isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude)) && !isNaN(parseFloat(measurementAmount));
        return isValidCoordinate;
      });
      const isValidNumbers = !numbersValid?.includes(false);
      // Validate if CSV headers match expected
      if (!headersMatch) {
        doSecondaryModalOpen(statusModal, { msg: ErrorMessages.InvalidHeaders });
      } else {
        // Validate coordinates and number values
        if (!isValidNumbers) {
          doSecondaryModalOpen(statusModal, { msg: ErrorMessages.InvalidCoordinates });
        }
        rowData.forEach((dataItem) => {
          columns.forEach((column) => {
            if (dataItem?.hasOwnProperty(column.columnDef?.header)) {
              let columnValue = cleanValue(dataItem[column.columnDef?.header]);
              // Map to column.id
              if (column.columnDef?.header && dataItem?.hasOwnProperty(column.columnDef?.header)) {
                if (['Latitude', 'Longitude', 'Measurement Amount'].includes(column.columnDef?.header)) {
                  dataItem[column.columnDef?.header] = !isNaN(columnValue) ? columnValue : '';
                } else {
                  dataItem[column.columnDef?.header] = columnValue;
                }
              }
            }
          });
        });
        // Validate USACE District
        const isValidDistrict = await doValidateGeometryInValidDistrict(generateMultiPointGeoJson(rowData));
        if (isValidDistrict) {
          addFeaturesFromRows(rowData);
        } else {
          doSecondaryModalOpen(statusModal, {
            msg: ErrorMessages.InvalidDistrict,
            status: 'CSV contains invalid coordinates',
          });
        }
        doResetComplexStateField({ name: 'arCsvData' });
      }
    };

    const handleFileInput = (e) => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      const fileExtensions = filesArr?.map((file) => file.name.split('.')[1]);
      if (fileExtensions.includes('zip') || fileExtensions.includes('gdb')) {
        handleGDBFileUpload(filesArr);
      } else {
        // Process files from CSV file
        processFiles(filesArr);
      }
    };

    const processFiles = async (fileNames) => {
      if (!fileNames || fileNames?.length === 0) return;

      const toastId = toast.loading('Processing file(s)...');

      try {
        let allRowData = [];
        for (const file of fileNames) {
          const fileContents = await readFile(file);
          const workbook = XLSX.read(fileContents, { type: 'buffer' });
          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            for (const cell in sheet) {
              if (cell[0] === '!') continue;
              if (sheet?.[cell]?.v !== undefined && sheet?.[cell]?.v !== null) {
                sheet[cell].v = cleanValue(String(sheet[cell].v));
                sheet[cell].t = 's';
              }
            }
            const rowObject = XLSX.utils.sheet_to_json(sheet);
            allRowData.push({ sheetName, rowObject });
          });
          const flattenedData = allRowData.flatMap((entry) => entry.rowObject);
          validateData(flattenedData);
        }
        tSuccess(toastId, 'File(s) processed successfully.');
      } catch (error) {
        console.error(error);
        tError(toastId, 'An error occurred while processing file(s).');
      }
    };

    const handleGDBFileUpload = useCallback(
      (files) => {
        const gdbFiles = files;
        const toastID = toast.loading('Processing GDB file...');
        const getGeometryData = async (file) => {
          await doGetGeometryData(file, false, toastID);
        };
        getGeometryData(gdbFiles[0]);
      },
      [doGetGeometryData]
    );

    useEffect(() => {
      csvData.length > 0 && processFiles(csvData);
    }, [JSON.stringify(csvData)]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className='header-buttons'>
        <input checked={selectAll} onChange={toggleSelectAll} title='Select All' type='checkbox' />
        {selectedRows?.length > 0 && (
          <Button className='remove-button' onClick={removeRows} size='small' title='Remove Selected Rows'>
            <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
            Remove Selected Rows
          </Button>
        )}
        <Button className='add-button' onClick={meta?.addRow} size='small' title='Add New Row'>
          <Icon focusable={false} className='mr-1' path={mdiPlusBox} size={'16px'} />
          Add New Row
        </Button>
        <FileInput
          accept='.csv,.gdb,.zip'
          aria-label='Upload CSV or ZIP File'
          id='ar-bulk-file'
          name='ar-bulk-file'
          onChange={handleFileInput}
          onDrop={handleFileInput}
        />
      </div>
    );
  }
);

export default AquaticResourcesHeaderCell;
