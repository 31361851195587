import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { decimalNumberRegex } from '@src/utils/regex';
import { calculateAcres , getProposedAmount } from '@src/utils/helpers';
import { ImpactsActivity, ImpactsResourceType } from '@src/utils/enums';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const ImpactAreaAmountTableCell = ({
  getValue,
  row,
  column,
  table,
  cell,
}) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [userInput, setUserInput] = useState(false);

  const rowImpactLength = useMemo(() => row.getValue('proposedLength'), [row]);
  const rowImpactWidth = useMemo(() => row.getValue('proposedWidth'), [row]);
  const rowImpactAmount = useMemo(() => row.getValue('proposedAmount'), [row]);
  const rowImpactAmountUnit = useMemo(() => row.getValue('amountUnit'), [row]);
  const rowImpactResourceType = useMemo(() => row.getValue('resourceType'), [row]);
  const rowImpactAmountType = useMemo(() => row.getValue('amountType'), [row]);
  const isActivity = useMemo(() => row.getValue('activity'), [row]);

  const debouncedUpdateRef = useRef();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(newValue) : newValue ?? newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, columnMeta?.type, tableMeta]);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef.current(newValue);
  }, []);

  const handleBlur = (e) => {
    // Clear field if value is 0 or is a negative number
    if (String(e?.target?.value) === '0' || String(e?.target?.value)[0] === '-') {
      setValue(null);
      updateValue(null);
    }
    if (column.id === 'proposedLength' || column.id === 'proposedWidth') {
      setUserInput(true);
    }
  };

  const handleChange = (e) => {
    const inputValue = e?.target?.value ?? '';
    if (decimalNumberRegex.test(inputValue) || inputValue === '') {
      setValue(inputValue === '' ? null : inputValue);
      updateValue(inputValue === '' ? null : inputValue);
    }
  };

  const isRiverStreamProposedDisabled = 
  rowImpactResourceType === ImpactsResourceType.RiverStream &&
  (
    (isActivity !== ImpactsActivity.TransportDredgedMaterial && 
     isActivity !== ImpactsActivity.DredgingSection) || 
    rowImpactAmountType === 'Removal Area'
  );
  const disableProposedAmount = ((typeof rowImpactLength === 'string' && rowImpactLength.trim() !== '') || (rowImpactLength !== null && rowImpactLength !== undefined)) || ((typeof rowImpactWidth === 'string' && rowImpactWidth.trim() !== '') || (rowImpactWidth !== null && rowImpactWidth !== undefined)) || isRiverStreamProposedDisabled;
  const disableLengthWidthCells = (rowImpactAmount !== null && rowImpactAmount !== undefined) && (rowImpactLength === null || rowImpactLength === undefined) && (rowImpactWidth === null || rowImpactWidth === undefined);

  const amountUnitRequired = !!rowImpactAmount;

  const isDisabled =
    ((column.id === 'proposedLength' || column.id === 'proposedWidth') && (((isActivity === ImpactsActivity.TransportDredgedMaterial || isActivity === ImpactsActivity.DredgingSection) && rowImpactAmountUnit === 'Cubic Yards') || disableLengthWidthCells)) ||
    ((column.id === 'proposedAmount' && (disableProposedAmount))) ||
    (column.id === 'amountUnit' && !rowImpactAmount);

  const cursorStyle = isDisabled ? { cursor: 'not-allowed' } : {};

  useEffect(() => {
    if (isActivity === ImpactsActivity.TransportDredgedMaterial) {
      if (column.id === 'proposedLength' || column.id === 'proposedWidth') {
        setValue(null);
        updateValue(null);
      }
    }
  }, [isActivity, column.id, updateValue]);

  useEffect(() => {
    if(rowImpactResourceType === ImpactsResourceType.RiverStream)
      if (isActivity !== ImpactsActivity.TransportDredgedMaterial || ((isActivity !== ImpactsActivity.DredgingSection) || 
    rowImpactAmountType === 'Removal Area')) {
        if (column.id === 'proposedAmount') {
          setValue(null);
          updateValue(null);
        }
      }
  }, [rowImpactResourceType, column.id, updateValue, isActivity, rowImpactAmountType]);
  
  useEffect(() => {
    if (!isNaN(rowImpactLength) && (!isNaN(rowImpactWidth)) && (rowImpactAmountUnit)) {
      let calculatedAmount;
      if (rowImpactAmountUnit === 'Acres') {
        calculatedAmount = calculateAcres(rowImpactLength, rowImpactWidth);
      } else if (rowImpactAmountUnit === 'Square Feet') {
        calculatedAmount = rowImpactLength * rowImpactWidth;
      } else {
        calculatedAmount = null;
      }
      if (rowImpactAmount !== calculatedAmount) {
        if (rowImpactLength !== null && rowImpactWidth !== null) {
          tableMeta?.updateData(row.index, 'proposedAmount', calculatedAmount);
        }
      }
    }
  }, [rowImpactLength, rowImpactWidth, rowImpactAmountUnit, rowImpactAmount, row.index, tableMeta.updateData, tableMeta]);
  
  useEffect(() => {
    if (!userInput && (rowImpactLength === null || rowImpactLength === undefined) && (rowImpactWidth === null || rowImpactWidth === undefined)) {
      updateValue(null);
    }
  }, [userInput, rowImpactLength, rowImpactWidth, updateValue]);

  useEffect(() => {
    if (column.id === 'proposedAmount') {
      let newAmount;
      if (rowImpactAmountUnit === 'Acres' ) {
        newAmount = calculateAcres(rowImpactLength, rowImpactWidth);
      } else if (rowImpactAmountUnit === 'Square Feet' ) {
        newAmount =  rowImpactLength * rowImpactWidth;
      } else {
        newAmount = rowImpactAmount !== undefined ? rowImpactAmount : null;
      }
      setValue(newAmount === 0 ? rowImpactAmount : newAmount);
      updateValue(newAmount === 0 ? rowImpactAmount : newAmount);
    }
  }, [rowImpactLength, rowImpactWidth, rowImpactAmountUnit, column.id, updateValue, rowImpactAmount]);

  return columnMeta?.type === 'select' ? (
    <select
      aria-label={'Proposed Length (in feet)'}
      disabled={isDisabled}
      id={cell.id}
      onBlur={handleBlur}
      onChange={handleChange}
      required={amountUnitRequired}
      style={{ width: '100%', ...cursorStyle }}
      value={value ?? ''}>
      <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
      {columnMeta?.options?.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  ) : (
    <input
      aria-label={'Proposed Length (in feet)'}
      disabled={isDisabled}
      id={cell.id}
      maxLength={columnMeta?.maxLength}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={getProposedAmount(isDisabled, rowImpactAmountUnit, rowImpactLength, rowImpactWidth)}
      readOnly={columnMeta?.readOnly}
      required={!isDisabled}
      style={{ width: '100%', ...cursorStyle }}
      type={columnMeta?.type || 'text'}
      value={value ?? getProposedAmount(isDisabled, rowImpactAmountUnit, rowImpactLength, rowImpactWidth)}
    />
  );
};
